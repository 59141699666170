import axios from 'axios';
import { BASE_URL } from '@/config/const';

class OfferAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/offer`, { params: payload });
  }

  async get(payload) {
    return await axios.get(`${BASE_URL}/v2/offer/room`, { params: payload });
  }

  async item(payload) {
    return await axios.get(`${BASE_URL}/v2/offer/item`, { params: payload });
  }

  async favorite(payload) {
    return await axios.post(`${BASE_URL}/v2/offer/favourite`, payload);
  }

  async favor(payload) {
    return await axios.post(`${BASE_URL}/v2/offer/favor`, payload);
  }
}

export default new OfferAPI();


