import Vue from 'vue';
import { replaceUrl } from '@/util';

Vue.directive('count-format', function(el, binding) {
  let value = '0';
  try {
    value = binding.value.toString();
  } catch (e) {
  }
  if (value.length < 5) {
    value = Number(value).toLocaleString();
  } else if (value.length >= 5 && value.length <= 6) {
    value = Number(value) / 1000;
    value = Math.floor(value) / 10;
    value = `${Number(value).toLocaleString()}万`;
  } else if (value.length >= 7 && value.length <= 8) {
    value = Number(value) / 10000;
    value = Math.floor(value);
    value = `${Number(value).toLocaleString()}万`;
  } else {
    value = Number(value) / 10000000;
    value = Math.floor(value) / 10;
    value = `${Number(value).toLocaleString()}億`;
  }
  el.innerHTML = value;
});

Vue.directive('new-line', function(el, binding) {
  el.innerHTML = replaceUrl((binding.value).replaceAll('\\n', '\n'));
});
