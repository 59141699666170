import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class AddressAPI {
  async create(payload) {
    return await axios.post(`${BASE_URL}/v2/privacy/address`, payload);
  }

  async get(payload = null) {
    return await axios.get(`${BASE_URL}/v2/privacy/address`, { params: payload, silent: true });
  }
}

export default new AddressAPI();


