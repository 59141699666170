import axios from 'axios';
import { BASE_URL } from '@/config/const';

class VideoAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/video`, { params: payload });
  }

  async get(payload) {
    return await axios.get(`${BASE_URL}/v2/video/${payload}`);
  }

  async getVideoUrl(payload) {
    return await axios.get(`${BASE_URL}/v2/video/${payload.id}/${payload.type}`);
  }

  async playList(payload) {
    return await axios.get(`${BASE_URL}/v2/video/playlist/${payload}`);
  }

  async purchase(payload) {
    return await axios.post(`${BASE_URL}/v2/video/${payload.id}/purchase`, { platformType: payload.platformType });
  }

  async history(id) {
    return await axios.post(`${BASE_URL}/v2/video/${id}/history`);
  }
}

export default new VideoAPI();


