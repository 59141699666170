import ShopAPI from '@/api/shop';

// initial state
const state = () => ({
  shop: null
});

// getters
const getters = {
  shop: (state) => state.shop
};

// actions
const actions = {
  async get({ state, commit }, id) {
    try {
      const res = await ShopAPI.get();
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    console.warn('/------- state.shop : ', data);
    state.shop = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
