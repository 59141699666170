import axios from 'axios';
import { BASE_URL } from '@/config/const';

class SerialCodeAPI {
  async entry(codes) {
    return await axios.post(`${BASE_URL}/v1/serialcode/entry`, { codes });
  }

  async register(codes) {
    return await axios.post(`${BASE_URL}/v1/serialcode/register`, { codes });
  }
}

export default new SerialCodeAPI();


