import axios from 'axios';
import { BASE_URL } from '@/config/const';
class LiveTalkMemberCheckAPI {
  async get(livetalkId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/meeting/streamer/check/livetalk/${livetalkId}`);
  }
}

export default new LiveTalkMemberCheckAPI();


