import axios from 'axios';
import { BASE_URL } from '@/config/const';

class TimeAPI {
  async get(config) {
    return await axios.get(`${BASE_URL}/v2/time`, config);
  }
}

export default new TimeAPI();


