// import CoinAPI from '@/api/coin';

// initial state
import BlockAPI from '@/api/block';

const state = () => ({
  list: []
});

// getters
const getters = {
  list: (state) => state.list
};

// actions
const actions = {
  async list({ state, commit }) {
    try {
      const res = await BlockAPI.get();
      if (res.data?.blockUserLists) {
        commit('set', res.data.blockUserLists.map((block) => {
          block.isHiddenByBlock = true;
          return block;
        }));
      }
    } catch (e) {
      throw e;
    }
  },
  async create({ state, commit }, id) {
    try {
      await BlockAPI.create({ target_account_id: id });
      commit('create', id);
    } catch (e) {
      throw e;
    }
  },
  async cancel({ state, commit }, id) {
    try {
      await BlockAPI.cancel({ target_account_id: id });
      commit('cancel', id);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.list = data;
  },
  create(state, id) {
    state.list = state.list.map((block) => {
      if (block.targetAccountId === id) block.isHiddenByBlock = true;
      return block;
    });
  },
  cancel(state, id) {
    state.list = state.list.map((block) => {
      if (block.targetAccountId === id) block.isHiddenByBlock = false;
      return block;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
