import axios from 'axios';
import { BASE_URL } from '@/config/const';

class CoinAPI {
  async get() {
    return await axios.get(`${BASE_URL}/v2/coin/balance`, { params: { platformType: 'WEB' } });
  }
}

export default new CoinAPI();


