import CoinAPI from '@/api/coin';

// initial state
const state = () => ({
  coin: null
});

// getters
const getters = {
  get: (state) => state.coin,
  free: (state) => parseInt(state.coin?.free || 0, 10),
  paid: (state) => parseInt(state.coin?.paid || 0, 10),
  total: (state) => parseInt(state.coin?.total || 0, 10)
};

// actions
const actions = {
  async get({ state, commit }) {
    try {
      const res = await CoinAPI.get();
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.coin = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
