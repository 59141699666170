// import CoinAPI from '@/api/coin';

// initial state
import ReportAPI from '@/api/report';

const state = () => ({
  reasons: []
});

// getters
const getters = {
  reasons: (state) => state.reasons
};

// actions
const actions = {
  async reasons({ state, commit }) {
    if (state.reasons.length > 0) return;
    try {
      const res = await ReportAPI.reason();
      if (res.data?.lists) {
        commit('set', res.data.lists);
      }
    } catch (e) {
      throw e;
    }
  },
  async create({ state, commit }, payload) {
    try {
      const res = await ReportAPI.create(payload);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.reasons = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
