<template>
  <header :class="['app-header', isShowHeader ? 'show' : '']" ref="header" v-if="!isHiddenHeader">
    <div ref="body" class="overlay" v-if="overlay"></div>
    <div class="header-wrap">
      <div class="header-logo" @click="onMoveTopPage()">
        <img src="@/assets/images/common_icon_pipmey.png" alt="">
      </div>
      <template>
        <template v-if="isSP">
          <template v-if="header_type === HEADER_TYPE.FULL && isLogin">
            <div class="header-right">
              <router-link to="/notification" class="header-nav-icon message" active-class="active">
                <img src="@/assets/images/header/message.png" alt="">
                <div v-if="noticeUnreadCount > 0" class="notice-badge">{{noticeUnreadCount}}</div>
              </router-link>
              <!-- <router-link to="/mypage" class="header-nav-icon alert" active-class="active">
                <img src="@/assets/images/header/alert.png" alt="">
              </router-link> -->
              <div class="header-coin" @click="onShopList">
                <img class="header-coin-icon" src="@/assets/images/common_icon_20.png" alt="">
                <span class="header-coin-value" v-currency="coinTotal"></span>
                <font-awesome-icon class="header-coin-plus" icon="fa-solid fa-plus" />
              </div>
              <button class="header-nav-icon" @click="openSideMenu">
                <font-awesome-icon icon="fa-solid fa-bars" />
              </button>
            </div>
          </template>
          <template v-else>
            <div class="header-right" v-if="header_type === HEADER_TYPE.FULL">
              <router-link to="/signup" class="navigation-item button fill" active-class="active">
                会員登録
              </router-link>
              <router-link to="/login" class="navigation-item button" active-class="active">
                ログイン
              </router-link>
              <a href="#" class="header-nav-icon" @click="openSideMenu">
                <font-awesome-icon icon="fa-solid fa-bars" />
              </a>
            </div>
          </template>
        </template>
        <template v-else>
          <template v-if="header_type === HEADER_TYPE.FULL && isLogin">
            <div class="header-center">
              <router-link to="/home" class="header-nav-icon" active-class="active">
                <div class="header-nav-icon-inner">
                  <img class="header-nav-icon-off" src="@/assets/images/footer/common_btn_tabnav01_off.png" alt="">
                  <img class="header-nav-icon-on" src="@/assets/images/footer/common_btn_tabnav01_on.png" alt="">
                </div>
                <div class="tooltip">ホーム</div>
              </router-link>
              <router-link id="headerChannel" to="/channel" class="header-nav-icon" active-class="active">
                <div class="header-nav-icon-inner">
                  <img class="header-nav-icon-off" src="@/assets/images/footer/common_btn_tabnav02_off.png" alt="">
                  <img class="header-nav-icon-on" src="@/assets/images/footer/common_btn_tabnav02_on.png" alt="">
                </div>
                <div class="tooltip">チャンネル</div>
              </router-link>
              <router-link id="headerCollection" to="/collection" class="header-nav-icon" active-class="active">
                <div class="header-nav-icon-inner">
                  <img class="header-nav-icon-off" src="@/assets/images/footer/common_btn_tabnav03_off.png" alt="">
                  <img class="header-nav-icon-on" src="@/assets/images/footer/common_btn_tabnav03_on.png" alt="">
                </div>
                <div class="tooltip">コレクション</div>
              </router-link>
              <router-link to="/profile" class="header-nav-icon" active-class="active">
                <div class="header-nav-icon-inner">
                  <img class="header-nav-icon-off" src="@/assets/images/footer/common_btn_tabnav04_off.png" alt="">
                  <img class="header-nav-icon-on" src="@/assets/images/footer/common_btn_tabnav04_on.png" alt="">
                </div>
                <div class="tooltip">プロフィール</div>
              </router-link>
            </div>
            <div class="header-right">
              <router-link to="/notification" class="header-nav-icon message" active-class="active">
                <img src="@/assets/images/header/message.png" alt="">
                <div v-if="noticeUnreadCount > 0" class="notice-badge">{{noticeUnreadCount}}</div>
              </router-link>
              <!-- <router-link to="/profile" class="header-nav-icon" active-class="active">
                <img src="@/assets/images/header/alert.png" alt="">
              </router-link> -->
              <div class="header-coin" @click="onShopList">
                <img class="header-coin-icon" src="@/assets/images/common_icon_20.png" alt="">
                <span class="header-coin-value" v-currency="coinTotal"></span>
                <font-awesome-icon class="header-coin-plus" icon="fa-solid fa-plus" />
              </div>
              <a href="#" class="header-nav-icon" @click="openSideMenu">
                <font-awesome-icon icon="fa-solid fa-bars" />
              </a>
            </div>
          </template>
          <template v-else>
            <div class="header-right" v-if="header_type === HEADER_TYPE.FULL">
              <router-link to="/signup" class="navigation-item button fill" active-class="active">
                会員登録
              </router-link>
              <router-link to="/login" class="navigation-item button" active-class="active">
                ログイン
              </router-link>
            </div>
          </template>
        </template>
      </template>
    </div>
    <transition name="slide-fade">
      <div class="navigation-sheet-wrap" v-if="overlay">
        <div class="navigation-overlay" @click="closeSideMenu"></div>
        <div class="navigation-sheet">
          <button class="navigation-sheet-close" @click="closeSideMenu">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
          <div class="navigation-sheet-navigations">
            <template v-for="nav in navigations">
              <template v-if="nav.key">
                <p @click="remoteConfig(nav.name, nav.key)" class="navigation-item" :key="nav.key">{{ nav.name }}</p>
              </template>
              <template v-else-if="nav.link">
                <a class="navigation-item" :href="nav.link" :key="nav.link" target="_blank">
                  <span>{{ nav.name }}</span>
                </a>
              </template>
              <template v-else>
                <router-link class="navigation-item" active-class="active" :to="nav.to" :key="nav.to">
                  <span>{{ nav.name }}</span>
                </router-link>
              </template>
            </template>
          </div>
          <div class="navigation-sheet-app">
            <div v-if="isSP" class="navigation-sheet-app-banners">
              <a :href="APP_ANDROID_STORE_LINK" class="navigation-sheet-app-banner navigation-sheet-app-banner-google" target="_blank">
                <img src="@/assets/images/googleplay.png" alt="">
              </a>
              <a :href="APP_IOS_STORE_LINK" class="navigation-sheet-app-banner navigation-sheet-app-banner-apple" target="_blank">
                <img src="@/assets/images/appstore.png" alt="">
              </a>
            </div>
<!--            <div class="navigation-sheet-app-logo">-->
<!--              <img src="@/assets/images/common_icon_pipmey.png" alt="">-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </transition>
    <ShopListModal v-if="isShopDialog" :is-shop-dialog="isShopDialog" @close="isShopDialog = false" />
    <RemoteConfigModal ref="RemoteConfigModal" />
  </header>
</template>

<script>
import { MainNavItems } from '@/data/main-nav-items';
import { APP_ANDROID_STORE_LINK, APP_IOS_STORE_LINK, HEADER_TYPE } from '@/config/const';
import ShopListModal from '@/components/ShopListModal';
import RemoteConfigModal from '@/components/RemoteConfigModal.vue';
import device from '@/mixin/device';

export default {
  name: 'Header',
  components: {
    ShopListModal,
    RemoteConfigModal
  },
  mixins: [device],
  data () {
    return {
      overlay: false,
      navigations: MainNavItems(false),
      HEADER_TYPE: HEADER_TYPE,
      header_type: HEADER_TYPE.FULL,
      scrollY: 0,
      isShow: true,
      isShopDialog: false,
      device: '',
      ishideHeader: false,
      isShowSmartBanner: false,
      APP_IOS_STORE_LINK: APP_IOS_STORE_LINK,
      APP_ANDROID_STORE_LINK: APP_ANDROID_STORE_LINK
    };
  },
  computed: {
    auth () {
      return this.$store.getters['auth/getAuth'];
    },
    isLogin () {
      return this.$store.getters['auth/isLogin'];
    },
    hasProfile () {
      return this.$store.getters['auth/hasProfile'];
    },
    isSP () {
      return this.$store.getters['screen/isSP'];
    },
    coinTotal () {
      return this.$store.getters['coin/total'];
    },
    noticeUnreadCount () {
      let _res = this.$store.getters['notification/getUnreadCount'] || 0;
      if (_res) {
        _res = parseInt(_res, 10);
      }
      return _res;
    },
    // device() {
    //   return this.$store.getters['device/get'];
    // },
    profile () {
      return this.$store.getters['profile/getProfile'] || null;
    },
    isShowHeader () {
      return !this.ishideHeader && (this.device === 'web' && this.isShow);
    },
    isHiddenHeader () {
      if (this.isUnity) return true;
      if (this.isSP && (this.$route.matched.some((record) => record.name === 'gacha-top' || record.name === 'gacha-result'))) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    auth () {
      this.init();
    },
    $route () {
      this.init();
    },
    isSP () {
      this.$nextTick(() => {
        this.$store.dispatch('screen/header', this.$refs.header?.clientHeight);
      });
    },
    scrollY (newValue, oldValue) {
      // 上にスクロールした時に表示
      if (this.isShopDialog === false) {
        const clientHeight = this.$refs.header?.clientHeight;
        const _root = this.isShowSmartBanner ? (80 + clientHeight) : clientHeight;
        this.isShow = !this.isSP || ((newValue) < (oldValue)) || (oldValue < _root || newValue < _root);
        this.$store.dispatch('screen/isHeaderShow', this.isShow);
      }
    }
  },
  created () {
    this.init();
  },
  mounted () {
    this.$store.dispatch('screen/header', this.$refs.header?.clientHeight);
    if (this.isShowHeader) {
      window.addEventListener('scroll', this.onScroll);
      window.addEventListener('load', () => {
        this.onScroll();
      });
    }
    this.isShowSmartBanner = !!document.querySelector('.smartbanner-show');
    if (this.isShowSmartBanner) {
      this.smartBannerObserver();
    }
  },
  methods: {
    async init () {
      this.closeSideMenu();
      this.header_type = this.$route.meta?.header_type || this.HEADER_TYPE.FULL;
      this.ishideHeader = this.$route.meta?.hideHeader || false;
      if (this.$route.matched.some((record) => record.name === 'profile')) {
        if (this.hasProfile) {
          if (this.$route.matched.some((record) => record.name === 'profile-confirm')) {
            this.header_type = this.HEADER_TYPE.SIMPLE;
          }
        } else {
          this.header_type = this.HEADER_TYPE.SIMPLE;
        }
      }
      this.device = this.$route.query?.device || 'web';
      this.overlay = false;
      this.navigations = MainNavItems(this.isLogin);
      if (this.isLogin) {
        const _deviceOS = this.device === 'web' ? this.device.toUpperCase() : this.deviceOS;
        await this.$store.dispatch('notification/unreadCount', { platformType: _deviceOS }).catch((e) => e);
        await this.$store.dispatch('coin/get').catch((e) => e);
      }
      this.$store.dispatch('screen/header', this.$refs.header?.clientHeight);
    },
    onScroll () {
      this.scrollY = window.pageYOffset;
      if (this.isShowSmartBanner) {
        const _top = (80 - this.scrollY) < 0 ? 0 : (80 - this.scrollY);
        document.documentElement.style.setProperty('---header-top', `${_top}px`);
      }
    },
    onShopList () {
      // this.$data.isShopDialog = true;
      this.$router.push({ query: this.$route.query, hash: 'shop' });
    },
    onMoveTopPage () {
      if (this.isLogin) {
        if (this.profile) {
          this.$router.push({ path: '/home' });
        } else {
          // this.$router.push({ path: '/profile/edit' });
          this.$router.push({ path: '/top' });
        }
      } else {
        this.$router.push({ path: '/top' });
      }
    },
    remoteConfig (title, key) {
      this.$refs.RemoteConfigModal.open(title, key);
    },
    openSideMenu () {
      this.overlay = true;
      this.$nextTick(() => {
        document.documentElement.classList.add('is-locked');
        this.$refs.body.addEventListener('pointermove', this.preventDefault);
      });
    },
    closeSideMenu () {
      document.documentElement.classList.remove('is-locked');
      if (this.$refs.body) this.$refs.body.removeEventListener('pointermove', this.preventDefault);
      this.overlay = false;
    },
    smartBannerObserver() {
      const target = document.querySelector('.smartbanner-show');
      const observer = new MutationObserver((mutations) => {
        for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        this.$nextTick(() => {
          console.log('target smartbanner-show class : ', newValue);
          console.log('target smartbanner-show class : ', !newValue);
          if (!newValue) {
            this.isShowSmartBanner = false;
            document.documentElement.style.setProperty('---header-top', `auto`);
            observer.disconnect();
          }
        });
      }
      });
      observer.observe(target, {
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class'],
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
:root {
  ---header-top: auto;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.app-header {
  max-width: 1920px;
  height: $header-pc;
  background: $white;
  border-radius: 0 0 6px 6px;
  position: sticky;
  top: 0;
  z-index: -1;
  width: 100%;
  &.show {
    z-index: 100;
  }
  box-shadow: 0px 0.3px 6px 0.2px lightgrey;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    background: linear-gradient(90deg, rgba(255,153,221,1) 0%, rgba(255,153,221,1) 16%, rgba(170,128,255,1) 16%, rgba(170,128,255,1) 32%, rgba(102,153,255,1) 32%, rgba(74,169,255,1) 48%, rgba(12,192,0,1) 48%, rgba(9,197,66,1) 64%, rgba(255,195,0,1) 64%, rgba(255,195,0,1) 80%, rgba(255,77,106,1) 80%, rgba(255,77,106,1) 100%);
  }
}
.header-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  .header-nav-icon {
    font-size: 2.2rem;
    color: $baseBlack;
  }
  .header-center {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
    .header-nav-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      position: relative;
      .header-nav-icon-off {
        display: block;
        height: 24px;
      }
      .header-nav-icon-on {
        display: none;
        height: 24px;
      }
      .tooltip {
        display: none;
        position: absolute;
        padding: 10px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.6em;
        color: #fff;
        border-radius: 5px;
        background: $black;
        box-shadow: 0px 4px 10px 0 rgba(95, 99, 104, 0.2);
        width: 85px;
        text-align: center;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: -24px;
        //   right: 60%;
        //   border: 15px solid transparent;
        //   border-top: 15px solid #000;
        //   margin-left: -15px;
        //   transform: rotateZ(180deg);
        // }
      }
      &.active {
        &::after {
          display: block;
          content: '';
          border-bottom: 4px solid $baseBlack;
          border-radius: 5px 5px 0 0;
          width: 100%;
          bottom: 0px;
          position: absolute;
        }
        .header-nav-icon-inner {
          .header-nav-icon-off {
            display: none;
          }
          .header-nav-icon-on {
            display: block;
          }
        }
      }
      &:hover {
        .header-nav-icon-inner {
          padding: 12px;
          border-radius: 24px;
          background-color: $baseWhite;
          .header-nav-icon-off {
            display: none;
          }
          .header-nav-icon-on {
            display: block;
          }
        }
        .tooltip{
          display: inline-block;
          top: 60px;
          left: 18px;
        }
      }
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .header-nav-icon {
      margin-left: 24px;
      img {
        height: 20px;
      }
    }
  }
  .header-coin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    height: 32px;
    margin-left: 24px;
    border-radius: 16px;
    background: $baseWhite;
    font-size: 1.2rem;
    padding: 0 8px;
    cursor: pointer;
    .header-coin-icon {
      width: 35px;
      height: 35px;
      margin-top: 8px;
    }
    .header-coin-value {
      display: block;
      flex: 1;
      text-align: right;
      padding: 0 4px;
    }
    .header-coin-plus {
    }
  }
  .navigation-item {
    font-size: 1.4rem;
    color: $baseBlack;
    margin-left: 32px;
    cursor: pointer;
    &.button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 128px;
      height: 40px;
      border-radius: 50px;
      border: 1px solid $baseBlack;
      &.fill {
        background: $baseBlack;
        color: $white;
      }
    }
  }
  .header-logo {
    cursor: pointer;
    img {
      width: 120px;
    }
  }
}

.navigation-sheet-wrap {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  .navigation-overlay {
    width: calc(100% - 500px);
  }
  .navigation-sheet {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: $white;
    background-image: url('~@/assets/images/common_bg_memu.png');
    background-color:rgba(255,255,255,0.2);
    background-blend-mode:lighten;
    background-size: cover;
    height: 100%;
    width: 500px;
    padding: 80px 40px 0;
    border-radius: 4px 0 0 4px;
    box-shadow: 0px 5px 15px 0 rgba(4, 0, 0, 0.2);
    overflow-y: scroll;
    .navigation-sheet-close {
      line-height: 0;
      position: absolute;
      top: 19px;
      right: 30px;
      width: 20px;
      font-size: 2.4rem;
      //img {
      //  width: 19px;
      //}
    }
    .navigation-sheet-navigations {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 15px;
      //margin-top: 30px;
      .navigation-item {
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px 0;
        width: 100%;
        color: $baseBlack;
      }
    }
    .navigation-sheet-buttons {
      border-top: 1px solid $grayText;
      margin: 0 -20px;
      padding: 20px;
      .navigation-sheet-btn {
        display: block;
        width: 100%;
        text-align: center;
        background: $witheeYellow;
        padding: 7px 16px;
        border-radius: 50px;
        color: $black1;
        margin-bottom: 20px;
        font-weight: bold;
        &.round {
          background: transparent;
          border: 1px solid $witheeYellow;
          color: $witheeYellow;
        }
      }
    }
    &-app {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      padding-bottom: 30px;
      &-logo {
        //margin-top: 30px;
        margin-bottom: 60px;
        width: 246px;
        img {
          width: 100%;
        }
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
.message {
  position: relative;
  .notice-badge {
    position: absolute;
    top: 2px;
    left: auto;
    right: -6px;
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.25;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and ( max-width:1023px) {
  .app-header {
    height: $header-sp;
  }
  .header-wrap {
    padding: 0 16px;
    .header-right {
      .header-nav-icon {
        margin-left: 16px;
        height: 20px;
        line-height: 20px;
        img {
          width: auto;
          height: 100%;
          vertical-align: top !important;
        }
        &.message {
          position: relative;
          .notice-badge {
            position: absolute;
            top: -5px;
            left: auto;
            right: -5px;
            width: 13px;
            height: 13px;
            background: red;
            border-radius: 50%;
            font-size: 0.9rem;
            text-align: center;
            line-height: 1.25;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .navigation-item {
        margin-left: 12px;
      }
    }
    .navigation-item {
      font-size: 1rem;
      &.button {
        width: 74px;
        height: 24px;
      }
    }
    .header-coin {
      width: 135px;
      height: 26px;
      margin-left: 15px;
      .header-coin-icon {
      }
      .header-coin-value {
      }
      .header-coin-plus {
      }
    }
    .header-logo {
      img {
        width: 90px;
        @media screen and (max-width: 335px) {
          width: 65px;
        }
      }
    }
  }
  .navigation-sheet-wrap {
    .navigation-overlay {
      width: calc(100% - 320px);
    }
    .navigation-sheet {
      width: 320px;
      padding: 60px 20px 30px;
      .navigation-sheet-navigations {
        row-gap: 10px;
        .navigation-item {
          font-size: 1rem;
          padding: 8px 0;
        }
      }
      &-app {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        &-banners {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        &-banner {
          display: block;
          height: 40px;
          img {
            height: 100%;
          }
        }
        &-banner-google {
          //width: 132px;
        }
        &-banner-apple {
          //width: 124px;
        }
        &-logo {
          margin-top: 30px;
          margin-bottom: 30px;
          width: 145px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
