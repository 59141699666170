import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class ReportAPI {
  async create(payload) {
    return await axios.post(`${BASE_URL}/v2/report`, payload);
  }

  async cancel(payload) {
    return await axios.put(`${BASE_URL}/v2/report`, payload);
  }

  async reason() {
    return await axios.get(`${BASE_URL}/v2/report/reason`, { silent: true });
  }
}

export default new ReportAPI();


