import axios from 'axios';
import { BASE_URL, USER_API_BASE_URL } from '@/config/const';
import Cookies from 'js-cookie';

class AuthAPI {
  async create(payload) {
    try {
      return await axios.post(`${USER_API_BASE_URL}/v1/auth/signup/prepare`, { email: payload.email, password: payload.password });
    } catch (e) {
      throw e;
    }
  }

  async resend(payload) {
    try {
      return await axios.post(`${USER_API_BASE_URL}/v1/auth/signup/resend`, { email: payload.email });
    } catch (e) {
      throw e;
    }
  }

  async signupEmail(payload) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/signup/email`, { email: payload.email, code: payload.code }, { silent: true });
  }

  async signupProvider(payload) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/signup/provider`, { email: payload.email, code: payload.code }, { silent: true });
  }

  async checkAuth(payload) {
    const token = Cookies.get('token');
    if (token) {
      return await axios.get(`${BASE_URL}/v2/account`, { silent: payload.silent });
    }
    return false;
  }

  // 認証必須
  async accountAuth(payload) {
    const token = Cookies.get('token');
    if (token) {
      return await axios.get(`${BASE_URL}/v2/account/auth`, { silent: payload.silent });
    }
    return false;
  }

  async signIn(payload) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/signin/email`, { email: payload.email, password: payload.password });
  }

  async resetPasswordPrepare(payload) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/reset/password/prepare`, { email: payload.email });
  }

  async resetPasswordCode(payload) {
    try {
      return await axios.post(`${USER_API_BASE_URL}/v1/auth/reset/password/code`, { email: payload.email, code: payload.code });
    } catch (e) {
      throw e;
    }
  }

  async resetPassword(payload) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/reset/password`, { email: payload.email, password: payload.password, code: payload.code });
  }

  async savePasswordPrepare() {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/save/password/prepare`);
  }

  async savePasswordCode(code) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/save/password/code`, { code }, { silent: true });
  }

  async savePassword(password) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/save/password`, { password });
  }

  async saveEmailPrepare(email) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/save/email/prepare`, { email });
  }

  async saveEmailCode(code) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/save/email`, { code }, { silent: true });
  }

  async disableProvider(provider) {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/disable/provider`, { provider });
  }

  async discard() {
    return await axios.post(`${BASE_URL}/v2/account/discard`);
  }

  async tokenRefresh() {
    return await axios.post(`${USER_API_BASE_URL}/v1/auth/refresh`, null, { refresh: true });
  }
}

export default new AuthAPI();
