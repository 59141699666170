import axios from 'axios';
import { BASE_URL } from '@/config/const';

class MaintenanceAPI {
  async get() {
    return await axios.get(`${BASE_URL}/v2/maintenance`, { maintenance: true });
  }
}

export default new MaintenanceAPI();
