// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkAPI from '@/api/livetalk';

const state = () => ({
  environment: null,
  monitor: null,
  talk: null
});

// getters
const getters = {
  environment: (state) => state.environment,
  monitorConfig: (state) => state.monitor,
  talkConfig: (state) => state.talk
};

// actions
const actions = {
  async getEnvironment({ state, commit }) {
    try {
      const res = await LiveTalkAPI.getEnvironment();
      if (res.data) {
        state.environment = res.data;
      }
    } catch (e) {
      throw e;
    }
  },
  async getMonitor({ state, commit }, partId) {
    try {
      const res = await LiveTalkAPI.getMonitor(partId);
      if (res.data) {
        state.monitor = res.data;
      }
    } catch (e) {
      throw e;
    }
  },
  async getTalk({ state, commit }, partId) {
    try {
      const res = await LiveTalkAPI.getTalk(partId);
      if (res.data) {
        state.talk = res.data;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
