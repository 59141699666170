export default {
  data() {
    return {
      replaceWidth: 1024
    };
  },
  created() {
    this.reportWindowSize();
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
  },
  methods: {
    reportWindowSize() {
      const windowWidth = window.innerWidth;
      const isSP = windowWidth < this.replaceWidth;
      this.$store.dispatch('screen/isSP', isSP);
    }
  }
};
