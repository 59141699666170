import BackgroundAPI from '@/api/background';
import cloneDeep from 'lodash/cloneDeep';

// initial state
const state = () => ({
  all: [],
  seek: null
});

// getters
const getters = {
  all: (state) => state.all,
  seek: (state) => state.seek
};

// actions
const actions = {
  async list({ state, commit }, payload) {
    try {
      console.warn('/------ payload : ', payload);
      const res = await BackgroundAPI.list(payload.seek);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async add({ state, commit }, payload) {
    try {
      console.warn('/------ payload : ', payload);
      const res = await BackgroundAPI.list(payload.seek);
      if (res.data) {
        commit('add', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async set({ state, commit }, backgroundId) {
    try {
      await BackgroundAPI.set(backgroundId, { params: { backgroundId: backgroundId } });
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.all = data.lists;
    state.seek = data.seek;
  },
  add(state, data) {
    const all = cloneDeep(state.all);
    all.push(...data.lists);
    state.all = all;
    state.seek = data.seek;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
