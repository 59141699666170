import TutorialAPI from '@/api/tutorial';

// initial state
const state = () => ({
  tips: []
});

// getters
const getters = {
  tips: (state) => state.tips
};

// actions
const actions = {
  async tips({ state, commit }, payload) {
    try {
      const res = await TutorialAPI.tips(payload);
      if (res) {
        commit('setTips', res.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setTips(state, data) {
    state.tips = data.tips;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
