import EventAPI from '@/api/event';

// initial state
const state = () => ({
  list: [],
  data: null,
  follows: []
});

// getters
const getters = {
  getList: (state) => state.list,
  getFollows: (state) => state.follows,
  get: (state) => state.data
};

// actions
const actions = {
  async list({ commit }) {
    try {
      const res = await EventAPI.list();
      if (res) {
        commit('setList', res.data.events);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ commit }, eventId) {
    commit('setData', null);
    try {
      const res = await EventAPI.get(eventId);
      if (res) {
        commit('setData', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async follow({ commit, dispatch }, eventId) {
    try {
      await EventAPI.follow(eventId);
      const res = await EventAPI.get(eventId);
      if (res) {
        commit('setData', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async unFollow({ commit, dispatch }, eventId) {
    try {
      await EventAPI.unFollow(eventId);
      const res = await EventAPI.get(eventId);
      if (res) {
        commit('setData', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async getFollow({ commit, dispatch }, accountId) {
    try {
      const res = await EventAPI.getFollow(accountId);
      if (res) {
        commit('setFollows', res.data.followEvent);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },
  setData(state, data) {
    state.data = data;
  },
  setFollows(state, data) {
    state.follows = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
