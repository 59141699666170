export const ERROR_TITLE = {
  InvalidArgument: 'エラー',
  NotFound: 'エラー',
  OutOfRange: 'エラー',
  Aborted: 'エラー',
  Unknown: 'エラー',
  Unimplemented: 'エラー',
  Internal: 'エラー',
  AlreadyVerified: 'エラー',
  AlreadySignedUp: 'エラー',
  AlreadyAssociated: 'エラー',
  AlreadyUsedEmail: 'エラー',
  LockedEmail: 'エラー',
  InvalidAccount: 'エラー',
  TimeExpiredVerification: '変更できませんでした',
  TimeExpiredAccount: 'エラー',
  LimitExceededOffer: 'エラー',
  DeadlineExceededSavePassword: 'エラー',
  DeadlineExceededResetPassword: 'エラー',
  InvalidCode: 'エラー',
  InvalidEmailFormat: 'エラー',
  InvalidEmailFormatRFC: 'エラー',
  InvalidPasswordFormat: 'エラー',
  InvalidPasswordFormatLength: 'エラー',
  InvalidPasswordFormatConsecutive: 'エラー',
  InvalidEmailOrPassword: 'ログインに失敗しました。',
  InvalidPasswordSignUp: 'エラー',
  InvalidUniqueName: 'エラー',
  LockedUniqueName: 'エラー',
  AlreadyExistsUniqueName: 'エラー',
  ActivatedProvider: 'エラー',
  AlreadyExistsEmail: 'エラー',
  InvalidModeration: 'エラー',
  InvalidAlphaNumeric: 'エラー',
  OutOfTerm: 'エラー',
  PermissionDenied: 'エラー',
  UnavailableChatPeriod: 'エラー',
  INVALID_SERIAL_CODE: 'エラー',
  EXPIRED_SERIAL_CODE: 'エラー',
  ALREADY_REGISTERED_SERIAL_CODE: 'エラー',
  NotFoundEmail: 'エラー',
  TimeExpiredPasswordReset: 'エラー',
  FailedPrecondition: 'エラー',
  GachaUnavailable: '購入期間外です',
  NotExistsPassword: 'エラー',
  UnavailablePeriod: '購入期間外です',
  NoGachaItemToDraw: '景品終了',
  UnavailablePostPeriod: '投稿期間外です',
  UnavailableIpPublicationPeriod: '対象のチャンネルは非公開です',
  UnavailableVideoPublicationPeriod: '視聴期間外です',
  DiscardedEmail: 'エラー',
  VoteRankingBonusTermChanged: 'エラー',
  VoteRankingVotingUnavailable: 'エラー',
  VoteRankingParticipateRewardUnavailable: 'エラー',
  VoteRankingBonusPhotoChanged: 'エラー'
};
export const ERROR_MESSAGE = {
  TimeExpiredPasswordReset: '認証コードの有効期限が切れています。',
  NotFoundEmail: 'このメールアドレスは登録されていません',
  InvalidArgument: '入力値が不正です。',
  NotFound: '存在しないデータへの参照が発生しています。',
  OutOfRange: '入力値が有効範囲外です。',
  Aborted: '操作が中断されました。',
  Unknown: '不明なエラーが発生しました。',
  Unimplemented: 'サポートされていません。',
  Internal: 'システム内部でエラーが発生しました。',
  AlreadyVerified: '既に認証済みです。',
  AlreadySignedUp: '既に登録済みのアカウントです。',
  AlreadyAssociated:
    'このアカウントは\nすでに別のユーザーアカウントに\n連携されているため追加できません。',
  AlreadyUsedEmail: 'このメールアドレスはすでに登録されています。',
  LockedEmail: 'このメールアドレスは利用できません。',
  InvalidAccount: '認証が完了していないアカウントです。',
  TimeExpiredVerification:
    '本人確認用の認証コードの有効期限が切れています。\n変更するには最初からやり直してください。',
  TimeExpiredAccount: 'アカウントの有効期限が切れています。',
  LimitExceededOffer:
    '認証コードの送信上限を超えています。時間をおいてやり直してください。',
  DeadlineExceededSavePassword: 'パスワード変更の期限を過ぎています。',
  DeadlineExceededResetPassword: 'パスワード再設定の期限を過ぎています。',
  InvalidCode: '認証コードが間違っています',
  InvalidEmailFormat: 'メールアドレスの形式が正しくありません。',
  InvalidEmailFormatRFC: '入力されたメールアドレスは利用できません。',
  InvalidPasswordFormat: 'パスワードの形式が正しくありません。',
  InvalidPasswordFormatLength:
    'パスワードは8文字以上25文字以内で入力してください。',
  InvalidPasswordFormatConsecutive:
    'パスワードに4文字以上の同じ文字は利用できません。',
  InvalidEmailOrPassword:
    'メールアドレスまたは、パスワードが違います。ご確認の上、再度入力ください。',
  InvalidPasswordSignUp:
    '認証コード送信ができません。再度、新規登録をし直してください。',
  InvalidUniqueName: 'このアカウント名は利用できません。',
  LockedUniqueName: 'このアカウント名は利用できません。',
  AlreadyExistsUniqueName: 'このアカウント名は使用されています。\n他のアカウント名をご使用ください。',
  ActivatedProvider: 'このアカウントでログイン中のため連携解除できません。',
  AlreadyExistsEmail: 'このメールアドレスは、既に使用されています。',
  InvalidModeration:
    '使用できない単語が含まれています。\n入力した内容を修正して、再度お試しください。',
  InvalidAlphaNumeric: '半角英数字のみ使用可能です。',
  OutOfTerm: '現在は対象期間外です。',
  PermissionDenied: '必要な権限がありません。',
  UnavailableChatPeriod: 'チャット投稿期間外のため、投稿できません。',
  INVALID_SERIAL_CODE: '入力されたコードが間違っています',
  EXPIRED_SERIAL_CODE: '入力されたコードの有効期限が切れています',
  ALREADY_REGISTERED_SERIAL_CODE: '既に登録済みのコードです',
  GachaUnavailable: '購入期間外のため、現在購入できません。',
  FailedPrecondition:
    '無料ガチャの開催期間が終了しました。ガチャTOPへ戻ります。',
  NotExistsPassword:
    'このメールアドレスのアカウントにはパスワード設定がされていません。\nソーシャルアカウントで登録している可能性があります。',
  UnavailablePeriod: '購入期間外のため、\n現在購入できません。',
  NoGachaItemToDraw: '購入回数分の景品が終了したためガチャを引くことができません。\nコインは消費されず、ガチャTOPへ戻ります。',
  UnavailablePostPeriod: '投稿期間外のため、投稿できません。',
  UnavailableIpPublicationPeriod:
    'このチャンネルはただいま非公開のため表示することができません。\nチャンネル再開後に改めてお楽しみください。',
  UnavailableVideoPublicationPeriod: '視聴期間外のため、\n現在視聴できません。',
  DiscardedEmail: 'このメールアドレスは利用できません。',
  VoteRankingBonusTermChanged: '所持ボーナスの対象フォトに変更があります。\n投票し直してください。',
  VoteRankingVotingUnavailable: 'ただいま投票期間外のため投票を受け付けておりません。',
  VoteRankingParticipateRewardUnavailable: '受け取り期間が終了したため、報酬を受け取ることができませんでした。',
  VoteRankingBonusPhotoChanged: '所持ボーナスの対象フォトに変更があります。\n投票し直してください。'
};

export const LOCK_FIVE_TIME_FAILED = 'LOCK_FIVE_TIME_FAILED';
