// initial state
import LiveTalkGroupMemberAPI from '@/api/livetalk/member/group';

const state = () => ({
  group: null,
  ip: null,
  livetalkList: []
});

// getters
const getters = {
  group: (state) => state.group,
  ip: (state) => state.ip,
  livetalkList: (state) => state.livetalkList
};

// actions
const actions = {
  async get({ state, commit }, id) {
    try {
      const res = await LiveTalkGroupMemberAPI.get(id);
      if (res.data) {
        state.group = res.data.group;
        state.ip = res.data.ip;
        state.livetalkList = res.data.livetalkList;
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.address = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
