import axios from 'axios';
import { BASE_URL } from '@/config/const';

class SpecialAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/special`, { params: payload });
  }
}

export default new SpecialAPI();


