import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class LiveTalkGroupAPI {
  async get(id) {
    return await axios.get(`${BASE_URL}/v2/livetalk/group/${id}/livetalk`);
  }
}

export default new LiveTalkGroupAPI();


