import axios from 'axios';
import { BASE_URL } from '@/config/const';

class ShopAPI {
  async get() {
    return await axios.get(`${BASE_URL}/v2/shop/coin`, { params: { platformType: 'WEB', 'seek.order': 'DESC' } });
  }

  async validate(id) {
    return await axios.get(`${BASE_URL}/v2/shop/coin/validate/${id}`, { silent: true, params: { platformType: 'WEB' } });
  }
}

export default new ShopAPI();


