import CurrencyAPI from '@/api/currency';

// initial state
const state = () => ({
  currency: null,
  list: [],
  limit: null,
  seek: null
});

// getters
const getters = {
  currency: (state) => state.currency,
  list: (state) => state.list,
  seek: (state) => state.seek || {},
  limit: (state) => state.limit
};

// actions
const actions = {
  async get({ state, commit }, payload) {
    try {
      const res = await CurrencyAPI.get(payload);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async next({ state, commit }, payload) {
    try {
      const res = await CurrencyAPI.get(payload);
      if (res.data) {
        commit('add', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async limit({ state, commit }, id) {
    try {
      const res = await CurrencyAPI.limit();
      if (res.data?.list) {
        commit('setLimit', res.data.list);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    console.warn('/------- state.currency : ', data);
    state.currency = data;
    state.list = data.list;
    state.seek = data.seek;
  },
  add(state, data) {
    state.list = [...state.list, ...data.list];
    state.seek = data.seek;
  },
  setLimit(state, data) {
    console.warn('/------- state.limit : ', data);
    state.limit = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
