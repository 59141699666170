// initial state
const state = () => ({
  show: false
});

// mutations
const mutations = {
  show(state) {
    state.show = true;
  },
  close(state) {
    state.show = false;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
