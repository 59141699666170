// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkAPI from '@/api/livetalk/livetalk';

const state = () => ({
  livetalk: null,
  ip: null,
  member: null,
  todoList: [],
  ticketCount: 0
});

// getters
const getters = {
  livetalk: (state) => state.livetalk,
  ip: (state) => state.ip,
  member: (state) => state.member,
  todoList: (state) => state.todoList,
  ticketCount: (state) => state.ticketCount
};

// actions
const actions = {
  async get({ state, commit }, { livetalkId }) {
    console.log(arguments);
    try {
      const res = await LiveTalkAPI.get(livetalkId);
      if (res.data) {
        state.livetalk = res.data.livetalk;
        state.ip = res.data.ip;
        state.member = res.data.member;
        state.todoList = res.data.todoList;
        state.ticketCount = res.data.ticketCount;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
