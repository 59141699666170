import axios from 'axios';
import { BASE_URL, PAYMENT_API_BASE_URL } from '@/config/const';
import { convertZuluTime } from '@/util';

class PurchaseAPI {
  async purchase(payload) {
    return await axios.post(`${BASE_URL}/v2/shop/coin/exchange/${payload.shopCoinId}`, { optionWeb: payload.optionWeb });
  }

  async history(payload = { gateway: 'SBPS' }) {
    payload.startOrderTime = convertZuluTime(payload.startOrderTime);
    payload.endOrderTime = convertZuluTime(payload.endOrderTime);
    return await axios.get(`${PAYMENT_API_BASE_URL}/v1/transaction`, { params: payload });
  }

  async creditCards(payload = { gateway: 'SBPS' }) {
    return await axios.get(`${BASE_URL}/v2/payment/sbps/creditcard`, { params: payload });
  }

  async creditCard(id) {
    return await axios.get(`${BASE_URL}/v2/payment/sbps/creditcard/${id}`, { silent: true });
  }

  async addCreditCard(payload) {
    return await axios.post(`${BASE_URL}/v2/payment/sbps/creditcard`, payload, { silent: true });
  }

  async remove(id) {
    return await axios.delete(`${BASE_URL}/v2/payment/sbps/creditcard/${id}`);
  }

  // shopクラスに移動させる
  async coin(id) {
    return await axios.get(`${BASE_URL}/v2/shop/coin/${id}`);
  }
}

export default new PurchaseAPI();


