import PurchaseAPI from '@/api/purchase';

// initial state
const state = () => ({
  history: [],
  creditCard: []
});

// getters
const getters = {
  getHistory: (state) => state.history,
  getCreditCard: (state) => state.creditCard
};

// actions
const actions = {
  async history({ commit }, params) {
    try {
      const res = await PurchaseAPI.history(params);
      if (res.data) {
        commit('setHistory', res.data.models);
      }
    } catch (e) {
      throw e;
    }
  },
  async creditCards({ commit }) {
    try {
      const res = await PurchaseAPI.creditCards();
      if (res.data) {
        commit('setCreditCard', res.data.models);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setHistory(state, data) {
    state.history = data;
  },
  setCreditCard(state, data) {
    state.creditCard = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
