import axios from 'axios';
import { BASE_URL } from '@/config/const';

class UserAPI {
  async get(accountId) {
    return await axios.get(`${BASE_URL}/v1/profile/get/${accountId}`);
  }

  async getTicket() {
    return await axios.get(`${BASE_URL}/v1/ticket`);
  }
}

export default new UserAPI();


