import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class LiveTalkAPI {
  async getEnvironment() {
    return await axios.get(`${BASE_URL}/v2/livetalk/environment`);
  }

  async getMonitor(partId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/meeting/${partId}/signin/monitor/official`);
  }

  async getTalk(partId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/meeting/${partId}/signin/talk/official`);
  }

  async permitUser(partId, payload) {
    return await axios.post(`${BASE_URL}/v2/livetalk/meeting/${partId}/permit`, payload);
  }
}

export default new LiveTalkAPI();


