import axios from 'axios';
import { BASE_URL } from '@/config/const';

class HomeAPI {
  async get() {
    return await axios.get(`${BASE_URL}/v2/home`, { params: { platform_type : 'WEB' } });
  }

  async photos(payload) {
    const { positionNum, params } = payload;
    return await axios.get(`${BASE_URL}/v2/home/photo/position/${positionNum}/candidate`, { params: params });
  }

  // async selectPhoto(payload) {
  //   return await axios.post(`${BASE_URL}/v2/home/photo/select`, payload);
  // }

  async resetPhoto(id) {
    return await axios.post(`${BASE_URL}/v2/home/photo/reset`, { photoId: id });
  }

  async unselectPhoto(positionNum) {
    return await axios.put(`${BASE_URL}/v2/home/photo/position/${positionNum}/unselect`);
  }

  async selectPhoto(payload) {
    return await axios.put(`${BASE_URL}/v2/home/photo/position/${payload.positionNumber}/select`, { acc_photo_id: payload.accPhotoId });
  }
}

export default new HomeAPI();


