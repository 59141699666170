import axios from "axios";
import { BASE_URL } from "@/config/const";
class LiveTalkMemberChannelAPI {
  async get(livetalkId, slotId) {
    return await axios.get(
      `${BASE_URL}/v2/livetalk/meeting/streamer/channel/livetalk/${livetalkId}/slot/${slotId}/contact`,
      { silent: true }
    );
  }

  async wait(livetalkId, slotId) {
    return await axios.get(
      `${BASE_URL}/v2/livetalk/meeting/streamer/channel/livetalk/${livetalkId}/slot/${slotId}/wait`,
      { silent: true }
    );
  }

  async waitRecordStart(livetalkId, slotId, reservationId, accountId) {
    return await axios.post(
      `${BASE_URL}/v2/livetalk/meeting/streamer/recording/livetalk/${livetalkId}/slot/${slotId}/reservation/${reservationId}/account/${accountId}/wait/start`,
      null,
      { silent: true }
    );
  }

  async waitRecordEnd(livetalkId, slotId, reservationId, accountId) {
    return await axios.post(
      `${BASE_URL}/v2/livetalk/meeting/streamer/recording/livetalk/${livetalkId}/slot/${slotId}/reservation/${reservationId}/account/${accountId}/wait/end`,
      null,
      { silent: true }
    );
  }

  async start(livetalkId, slotId, reservationId, accountId) {
    return await axios.post(
      `${BASE_URL}/v2/livetalk/meeting/streamer/talk/livetalk/${livetalkId}/slot/${slotId}/reservation/${reservationId}/account/${accountId}/start`,
      null,
      { silent: true }
    );
  }

  async end(livetalkId, slotId, reservationId, accountId) {
    return await axios.post(
      `${BASE_URL}/v2/livetalk/meeting/streamer/talk/livetalk/${livetalkId}/slot/${slotId}/reservation/${reservationId}/account/${accountId}/end`,
      null,
      { silent: true }
    );
  }

  async forceExit(livetalkId, slotId, reservationId, accountId) {
    return await axios.post(
      `${BASE_URL}/v2/livetalk/meeting/streamer/operation/livetalk/${livetalkId}/slot/${slotId}/reservation/${reservationId}/account/${accountId}/force_exit`,
      null,
      { silent: true }
    );
  }
}

export default new LiveTalkMemberChannelAPI();
