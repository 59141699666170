import UserAPI from '@/api/user';

// initial state
const state = () => ({
  profile: null,
  availableEvents: [],
  unavailableEvents: [],
  events: []
});

// getters
const getters = {
  getProfile: (state) => state.profile,
  getAvailableEvents: (state) => state.availableEvents,
  getUnavailableEvents: (state) => state.unavailableEvents,
  getEvents: (state) => state.events
};

// actions
const actions = {
  async get({ state, commit }, accountId) {
    try {
      const res = await UserAPI.get(accountId);
      if (res.data) {
        commit('setProfile', res.data.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async getTicket({ commit }) {
    try {
      const res = await UserAPI.getTicket();
      if (res.data) {
        commit('setTicket', res.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setProfile(state, data) {
    state.profile = data;
  },
  setTicket(state, data) {
    state.availableEvents = data.availableEvents;
    state.unavailableEvents = data.unavailableEvents;
    state.events = data.events;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
