<template>
  <div class="container" ref="container" :style="style">
    <router-view></router-view>
  </div>
</template>


<script>
import resolution from '@/mixin/resolution';

export default {
  name: 'Children',
  mixins: [resolution],
  data() {
    return {
      style: {
        backgroundImage: '',
        backgroundSize: 'cover',
        paddingBottom: '0',
        backgroundPositionX: 'center'
      }
    };
  },
  computed: {
    header() {
      return this.$store.getters['screen/header'];
    },
    footer() {
      return this.$store.getters['screen/footer'];
    },
    background() {
      return this.$store.getters['theme/getBackground'];
    },
    isLogin() {
      return this.$store.getters['auth/isLogin'];
    },
    isSP() {
      return this.$store.getters['screen/isSP'];
    }
  },
  watch: {
    header() {
      this.fixHeight();
    },
    footer() {
      this.$nextTick(() => {
        this.fixHeight();
      });
    },
    $route() {
      this.$nextTick(() => {
        this.setDisableSave();
        this.fixHeight();
      });
    },
    background() {
      this.style.backgroundImage = `url(${this.background})`;
    }
  },
  mounted() {
    this.setDisableSave();
    this.fixHeight();
  },
  methods: {
    setDisableSave() {
      const _imgTags = Array.from(document.getElementsByTagName('img'));
      _imgTags.map((tag) => {
        tag.classList.add('disableSave');
        tag.addEventListener('contextmenu', (event) => event.preventDefault());
        return tag;
      });
    },
    fixHeight() {
      // ログインフッターはfixedなので、余白を取る
      const footerEle = document.getElementById('footer');
      const hideFooter = !!this.$route.meta.hideFooter;
      if (!hideFooter && this.isLogin) {
        const _isExist = ['gacha-top', 'movie-detail', 'notification-detail', 'draw', 'offer'].some((el) => this.$route.name.includes(el));
        if (_isExist) {
          this.style.paddingBottom = '0';
        } else {
          if (!this.isSP && this.$route.name.includes('collection')) {
            this.style.paddingBottom = '64px';
          } else {
            this.style.paddingBottom = `${footerEle?.getClientRects()[0].height}px`;
          }
        }
      } else {
        this.style.paddingBottom = '0';
      }
      const clientWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth;
      document.documentElement.style.setProperty('vw',`${clientWidth / 100}px`);
      this.setFooterHeight();
    },
    setFooterHeight() {
      const target = document.getElementById('footer');
      if (target) {
        document.documentElement.style.setProperty('---footer',`${target.getClientRects()[0].height}px`);
      } else {
        document.documentElement.style.setProperty('---footer',`3%`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  :root {
    ---footer: 79.16px
  }

  .container {
    //min-height: 100vh;
    background: #f5f5f5;
  }

  .disableSave {
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
  }
</style>
