import MaintenanceAPI from '@/api/maintenance';

const state = () => ({
  list: []
});

// getters
const getters = {
  list: (state) => state.list,
  underMaintenance: (state) => state.list.length > 0
};

// actions
const actions = {
  async list({ state, commit }) {
    try {
      const res = await MaintenanceAPI.get();
      if (res.data.list) {
        commit('set', res.data.list);
      }
    } catch (e) {
      throw e;
    }
  },
  async checkAll({ state, commit }) {
    try {
      const res = await MaintenanceAPI.get();
      if (res.data.list) {
        const isAll = res.data.list.some((data) => !data.isPartial);
        if (isAll) {
          commit('set', res.data.list);
        }
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.list = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
