import VoteRankingAPI from '@/api/vote/ranking';

// initial state
const state = () => ({
  bonus: null
});

// getters
const getters = {
  bonus: (state) => state.bonus
};

// actions
const actions = {

  async bonus({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.bonusPhoto(id);
      state.bonus = res.data?.bonus || null;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
