import axios from 'axios';
import { BASE_URL } from '@/config/const';

class LiveTalkPersonalAuthAPI {
  async get(accountId) {
    console.log('accountId', accountId);
    return await axios.get(`${BASE_URL}/v2/identity/verification`, { params: { member_id: accountId } });
  }
}

export default new LiveTalkPersonalAuthAPI();


