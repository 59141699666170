import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, collection, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { firebaseConfig } from '@/config/firebaseConfig';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import Cookies from 'js-cookie';
import store from '@/store';

class Firebase {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(this.app);
    console.log(analytics);
    this.remoteConfig = getRemoteConfig(this.app);
    this.db = getFirestore(this.app);
    this.isLogin = false;
    // cacheing on firebase store
    // this.remoteConfig.settings.minimumFetchIntervalMillis = 0;
  }

  async login() {
    await store.dispatch('auth/tokenRefresh');
    const token = Cookies.get('firebaseToken');
    if (!token || this.isLogin) return;
    const auth = getAuth(this.app);
    const userCredential = await signInWithCustomToken(auth, token).catch((error) => {
      Cookies.remove('firebaseToken');
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    });
    if (!userCredential) return;
    const user = userCredential.user;
    this.isLogin = true;
    console.log('firebase login: ', user);
  }

  collection(collectionName) {
    return collection(this.db, collectionName);
  }

  async doc(collectionName, docName) {
    const docRef = doc(this.db, collectionName, docName);
    return await getDoc(docRef);
  }

  async setDoc(collectionName, docName, docData) {
    const docRef = doc(this.db, collectionName, docName);
    return await setDoc(docRef, docData);
  }

  async deleteDoc(collectionName, docName) {
    const docRef = doc(this.db, collectionName, docName);
    return await deleteDoc(docRef);
  }

  async fetchAndActivate() {
    await fetchAndActivate(this.remoteConfig);
  }

  getConfigValue(key) {
    return getValue(this.remoteConfig, key);
  }
}

export default new Firebase();
