<template>
  <div :class="['wrapper', `wrapper-${$route.name}`]" ref="wrapper">
    <router-view name="header" />
    <router-view></router-view>
    <router-view name="footer"/>
    <ShopListModal v-if="isShopDialog" :is-shop-dialog="isShopDialog" @close="closeModal" />
    <RemoteConfigModal ref="RemoteConfigModal" />
  </div>
</template>


<script>
import ShopListModal from '@/components/ShopListModal.vue';
import RemoteConfigModal from '@/components/RemoteConfigModal.vue';

export default {
  name: 'Default',
  components: { RemoteConfigModal, ShopListModal },
  data() {
    return {
      isShopDialog: false
    };
  },
  watch: {
    $route() {
      // this.$refs.wrapper.style.minHeight = `${innerHeight}px`;
      this.showModalByQuery();
    }
  },
  mounted() {
    this.showModalByQuery();
    this.$store.dispatch('device/set', this.$route.query?.device || 'web');
  },
  methods: {
    showModalByQuery() {
      if (this.$route.hash === '#shop') {
        this.isShopDialog = true;
      } else if (this.$route.hash === '#commercial') {
        this.$refs.RemoteConfigModal.open('特定商取引法に基づく表記','SPECIFIED_COMMERCIAL_TRANSACTIONS');
      }
    },
    closeModal(resetQuery = false) {
      this.isShopDialog = false;
      if (resetQuery) this.$router.push({ query: this.$route.query, hash: null });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
}
@media screen and (max-width: 1023px) {
  .wrapper {
    min-height: unset;
    &.wrapper-profile-follow {
      min-height: 100vh;
    }
  }
}
</style>
