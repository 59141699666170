import axios from 'axios';
import { BASE_URL } from '@/config/const';

class StoryAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/story/group`, { params: payload });
  }

  async get(payload) {
    return await axios.get(`${BASE_URL}/v2/story/group/${payload.ipId}/${payload.storyGroupId}`);
  }

  async getIp(id) {
    return await axios.get(`${BASE_URL}/v2/story/ip/${id}`);
  }

  async purchase(payload) {
    const datas = {
      platformType: 'WEB'
    };
    if (payload.needsPurchase) {
      datas.needsPurchase = payload.needsPurchase;
    }
    return await axios.post(`${BASE_URL}/v2/story/group/${payload.ipId}/${payload.story.storyGroupId}/view/${payload.story.storyEpisodeId}`, datas);
  }
}

export default new StoryAPI();


