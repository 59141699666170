<template>
  <div id="main">
    <Loading/>
    <MaintenanceModal />
    <router-view v-if="init" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import MaintenanceModal from '@/components/MaintenanceModal';
import Firebase from '@/api/firebase';
import { FOOTER_TYPE } from '@/config/const';
import SmartBanner from '@/plugins/smart-app-banner';

export default {
  name: 'App',
  components: {
    Loading,
    MaintenanceModal
  },
  props: {
    footer_type: {
      type: Number,
      default: FOOTER_TYPE.FULL
    }
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },
  data () {
    return {
      init: false
    };
  },
  created () {
    const params = new URLSearchParams(window.location.search);
    if (params.get('device') !== 'unity') {
      new SmartBanner({
        daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: 'Pipmey',
        author: '10antz',
        button: '開く',
        store: {
          ios: 'Pipmeyで開く',
          android: 'Pipmeyで開く'
        },
        icon: 'https://pipmey.jp/src/assets/images/store/playstore-icon_512.png'
      });
    }
  },
  async mounted () {
    await Firebase.fetchAndActivate();
    this.init = true;
    window.oncontextmenu = () => {
      return false;
    };
  }
};
</script>
<style>
#main {
  background-image: url('~@/assets/images/top/bg_repeat.svg');
  background-size: 100%;
  background-repeat: repeat;
}
.swal2-container {
  z-index: 9999999999 !important;
}
</style>
