<template>
  <transition name="confirm-modal">
    <div class="confirm-modal" v-if="show">
      <div class="confirm-modal-overlay" @click="close"></div>
      <div class="confirm-modal-contain">
        <button class="confirm-modal-close-btn" @click.prevent="close">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
        <template v-if="isAll">
          <div class="confirm-modal-contain-body">
            <p class="confirm-modal-contain-title">
              メンテナンス中
            </p>
            <p class="confirm-modal-contain-subtitle">
              {{allMaintenance.message}}
            </p>
            <div class="confirm-modal-contain-date">
              <p class="confirm-modal-contain-date-title">メンテナンス期間</p>
              <p class="confirm-modal-contain-date-text">{{allMaintenance.date}}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="confirm-modal-contain-body">
            <p class="confirm-modal-contain-title">
              メンテナンス中
            </p>
            <p class="confirm-modal-contain-subtitle">
              {{message}}
            </p>
            <div class="maintenance-detail">
              <p class="maintenance-detail-title">メンテナンス詳細</p>
              <div class="maintenance-detail-scroll">
                <div class="maintenance-detail-scroll-body" v-for="mainte in maintenanceList" :key="mainte.maintenanceId">
                  <p class="maintenance-detail-scroll-body-title">【期間】</p>
                  <p class="maintenance-detail-scroll-body-text">{{ mainte.date }}</p>
                  <p class="maintenance-detail-scroll-body-title">【メンテナンス箇所】</p>
                  <p class="maintenance-detail-scroll-body-text">
                    {{mainte.category}}
                  </p>
                  <p class="maintenance-detail-scroll-body-title">【対象チャンネル】</p>
                  <p class="maintenance-detail-scroll-body-text">
                    {{mainte.targetsAllIps ? '全チャンネル' : mainte.ipName}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="confirm-modal-contain-buttons">
          <CommonButton
              round
              @click="close"
              width="100%"
          >
            {{confirmText}}
          </CommonButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CommonButton from '@/components/CommonButton';
import moment from 'moment';
export default {
  name: 'MaintenanceModal',
  components: {
    CommonButton
  },
  computed: {
    maintenance() {
      return this.$store.getters['maintenance/list'];
    }
  },
  watch: {
    maintenance(val, old) {
      if (this.maintenance.length > 0) {
        this.open();
        this.isAll = this.maintenance.some((data) => !data.isPartial);
        if (this.isAll) {
          this.allMaintenance = this.maintenance.find((data) => !data.isPartial);
          this.allMaintenance.date = `${moment(this.allMaintenance.startTime).format('YYYY/MM/DD HH:mm')} ~ ${moment(this.allMaintenance.endTime).format('YYYY/MM/DD HH:mm')}`;
        } else {
          this.maintenanceList = this.maintenance.filter((data) => data.isPartial).map((data) => {
            const ipName = data.targetIpNames.join('\n');
            const category = data.targetCategoryNames.join('\n');
            const date = `${moment(data.startTime).format('YYYY/MM/DD HH:mm')} ~ ${moment(data.endTime).format('YYYY/MM/DD HH:mm')}`;
            return {
              ipName: ipName,
              category: category,
              date: date,
              ...data
            };
          });
        }
      } else {
        this.close();
      }
    }
  },
  data() {
    return {
      show: false,
      isAll: true,
      confirmText: '閉じる',
      hint: '',
      message: 'ただ今、指定の箇所でメンテナンスを行なっております。\nメンテナンスの詳細については以下の記載をご確認お願いいたします。\nユーザの皆さまにはご迷惑をおかけいたしますが、再開までしばらくお待ちください。',
      maintenanceList: [],
      allMaintenance: {}
    };
  },
  mounted() {
  },
  methods: {
    close() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('device') === 'unity') {
        location.href = `uniwebview://maintenance`;
      } else {
        if (this.isAll) {
          this.$store.dispatch('profile/reset');
          this.$store.dispatch('address/reset');
          this.$store.dispatch('auth/logout');
          if (this.$route.path !== '/top') {
            this.show = false;
            this.$router.push('/top');
          }
        } else {
          this.show = false;
          this.$router.push('/home');
        }
      }
    },
    open() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('device') === 'unity') {
        location.href = `uniwebview://maintenance`;
      } else {
        this.show = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.confirm-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.confirm-modal-contain {
  white-space: pre-line;
  position: relative;
  z-index: 100;
  width: 800px;
  height: auto;
  padding: 24px 40px;
  box-sizing: border-box;
  background: $white;
  text-align: center;
  border-radius: 8px;
  .confirm-modal-close-btn {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 3rem;
    color: $baseBlack;
  }
  .confirm-modal-contain-title {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .confirm-modal-contain-subtitle {
    font-size: 1.6rem;
    margin-bottom: 32px;
    color: $baseDark;
    padding: 0 40px;
    text-align: center;
  }
  .confirm-modal-contain-buttons {
    width: 256px;
    margin: 0 auto;
  }
  .confirm-modal-contain-date-title {
    font-size: 2rem;
    color: $baseDark;
    text-align: left;
    line-break: normal;
    margin-bottom: 16px;
  }
  .confirm-modal-contain-date-text {
    font-size: 2rem;
    color: $baseDark;
    padding: 14px;
    background: $baseWhite;
    border-radius: 4px;
    margin-bottom: 32px;
  }
}

.maintenance-detail {
  .maintenance-detail-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: $baseDark;
    margin-bottom: 8px;
  }
  .maintenance-detail-scroll {
    height: 300px;
    overflow-y: scroll;
    text-align: left;
    line-break: normal;
    background-color: $baseWhite;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    .maintenance-detail-scroll-body {
      font-size: 1.6rem;
      padding: 8px 0;
      &:not(:last-child) {
        border-bottom: 1px solid $baseLight;
      }
    }
    .maintenance-detail-scroll-body-title {
      font-weight: bold;
    }
  }
}

.confirm-modal-enter-active {
  transition: all .3s ease;
}
.confirm-modal-leave-active {
  transition: all .3s ease;
}
.confirm-modal-enter, .confirm-modal-leave-to {
  opacity: 0;
}

@media screen and ( max-width:1023px) {
  .confirm-modal-contain {
    width: 90%;
    padding: 24px 10px;
    border-radius: 4px;
    .confirm-modal-close-btn {
      font-size: 2rem;
    }
    .confirm-modal-contain-title {
      font-size: 1.4rem;
      margin-bottom: 12px;
    }
    .confirm-modal-contain-subtitle {
      font-size: 1.2rem;
      margin-bottom: 12px;
      padding: 0;
      text-align: center;
      line-break: normal;
    }
    .confirm-modal-contain-buttons {
      width: 140px;
    }
    .confirm-modal-contain-date-title {
      font-size: 1.2rem;
      margin-bottom: 12px;
    }
    .confirm-modal-contain-date-text {
      font-size: 1.2rem;
      margin-bottom: 16px;
    }
  }
  .maintenance-detail {
    .maintenance-detail-title {
      font-size: 1.2rem;
      margin-bottom: 8px;
    }
    .maintenance-detail-scroll {
      height: 230px;
      .maintenance-detail-scroll-body {
        font-size: 1.2rem;
        padding: 4px 0;
      }
    }
  }
}
</style>
