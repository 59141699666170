import group from '@/store/modules/livetalk/group';
import livetalk from '@/store/modules/livetalk/livetalk';
import ticket from '@/store/modules/livetalk/ticket';
import reserve from '@/store/modules/livetalk/reserve';
import info from '@/store/modules/livetalk/info';
import member from '@/store/modules/livetalk/member/index';
import auth from '@/store/modules/livetalk/auth';
export default {
  modules: {
    livetalk: {
      namespaced: true,
      modules: {
        group,
        livetalk,
        ticket,
        reserve,
        info,
        member,
        auth
      }
    }
  }
};
