<template>
  <CustomModal
      id="ContactModal"
      full
      stretch
      bg="white"
      @close="close"
      v-if="show"
  >
    <template v-slot:header>
      <span class="header-title">メールで問い合わせる</span>
    </template>
    <template v-slot:body>
      <iframe class="iframe" :src="path" />
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/CustomModal.vue';
import { CONTACT_URL } from '@/config/const';
import { userInfo } from '@/util';
export default {
  name: 'ContactModal',
  components: {
    CustomModal
  },
  data() {
    return {
      path: '',
      show: false
    };
  },
  created() {
    const user = userInfo();
    const userId = user.accountId;
    const userName = user.uniqueName;
    const device = user.device;
    const os = user.os;
    const appVersion = 'null';
    const netType = 'null';
    this.path = `${CONTACT_URL}&userAppId=${userId}&userName=${userName}&device=${device}&os=${os}&appVersion=${appVersion}&netType=${netType}`;
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
.iframe {
  width: 100%;
  height: 100%;
}
</style>
