import ProfileAPI from '@/api/profile';

// initial state
const state = () => ({
  profile: null,
  channelFollowers: 0,
  temp: null
});

// getters
const getters = {
  getProfile: (state) => state.profile,
  nickName: (state) => state.profile?.nickName || '',
  uniqueName: (state) => state.profile?.uniqueName || '',
  channelFollowers: (state) => state.channelFollowers,
  isOfficial: (state) => state.profile.isOfficial,
  getTemp: (state) => state.temp
};

// actions
const actions = {
  async fetch({ state, commit }, payload = { silent: false }) {
    if (!state.profile) {
      try {
        const res = await ProfileAPI.get(payload);
        if (res.data) {
          commit('setProfile', res.data);
        }
      } catch (e) {
        throw e;
      }
    }
  },
  async get({ state, commit }, payload = { silent: false }) {
    try {
      const res = await ProfileAPI.get(payload);
      if (res.data) {
        commit('setProfile', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async create({ commit }, payload) {
    try {
      const res = await ProfileAPI.create(payload);
      if (res.data) {
        commit('resetProfile');
      }
    } catch (e) {
      throw e;
    }
  },
  reset({ commit }) {
    try {
      commit('resetProfile');
      commit('setTemp', null);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setProfile(state, data) {
    state.profile = data.profile;
    state.channelFollowers = data.channelFollowers;
  },
  setTemp(state, data) {
    state.temp = data;
  },
  resetProfile(state) {
    state.profile = null;
    state.channelFollowers = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
