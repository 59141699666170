import ranking from '@/store/modules/vote/ranking';
export default {
  modules: {
    vote: {
      namespaced: true,
      modules: {
        ranking
      }
    }
  }
};
