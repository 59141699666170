import Vue from 'vue';

Vue.directive('currency', function(el, binding) {
  const value = binding.value || 0;
  if (binding.arg === 'unit') {
    el.innerHTML = `${Number(value).toLocaleString()}<span class="currency-unit">円</span>`;
  } else {
    el.innerHTML = `${Number(value).toLocaleString()}`;
  }
});
