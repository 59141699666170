import axios from 'axios';
import { BASE_URL } from '@/config/const';

class GachaAPI {
  async list(id) {
    return await axios.get(`${BASE_URL}/v2/gacha`, { params: { ipId: id } });
  }

  async getGachaInfo(id) {
    return await axios.get(`${BASE_URL}/v2/gacha/${id}`);
  }

  async get(id) {
    return await axios.get(`${BASE_URL}/v2/gacha/${id}/detail`);
  }

  async draw(payload) {
    return await axios.post(`${BASE_URL}/v2/gacha/${payload.id}/draw`, payload);
  }

  async agree(id) {
    return await axios.post(`${BASE_URL}/v2/gacha/${id}/agree`);
  }

  async getStockoutList(id) {
    return await axios.get(`${BASE_URL}/v2/gacha/${id}/stockout`);
  }
}

export default new GachaAPI();


