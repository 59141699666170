import moment from 'moment';
import ua from 'ua-parser-js';
import store from '@/store';
import { runeMap } from '@/config/runeMap';
import { STATIC_URL } from '@/config/const';

export function convertZuluTime (time) {
  if (!time) return '';
  const _time = time.replace(/Z$/, '');
  return `${moment(_time).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
}

export function decodeJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}

export function maskEmail (email) {
  const local = email.split('@')[0];
  const domain = email.split('@')[1];
  const domains = domain.split('.');
  let maskedLocal = '';
  maskedLocal = replaceRange(local, 1, local.length, '*');
  const maskedDomain = domains.map((domain, index) => {
    if (index === 0) {
      return replaceRange(domain, 1, domain.length, '*');
    } else {
      return replaceRange(domain, 0, domain.length, '*');
    }
  });
  return `${maskedLocal}@${maskedDomain.join('.')}`;
}

export function replaceRange (s, start, end, substitute) {
  return s.substring(0, start) + substitute.repeat(end - start) + s.substring(end);
}

export function findUrl (str) {
  const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;
  return str.match(regexp_url);
}

export function replaceUrl (str, escape) {
  if (!str) return str;
  const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;
  const _str = escape ? escapeHtml(str) : str;
  const regexp_makeLink = function (all, url, h, href) {
    return `<a href="h${href}" target="_blank" class="link">${url}</a>`;
  };
  return _str.replace(regexp_url, regexp_makeLink);
}

export function escapeHtml (string) {
  if (typeof string !== 'string') {
    return string;
  }
  return string.replace(/[&'`"<>]/g, function (match) {
    return {
      '&': '&amp;',
      '\'': '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;'
    }[match];
  });
}

export function getBase64StringFromDataURL (dataURL) {
  return dataURL.replace('data:', '').replace(/^.+,/, '');
}

export function retryPromise (promiseFn, maxRetries) {
  return new Promise((resolve, reject) => {
    let retryCount = 0;

    function executePromise () {
      promiseFn().then(resolve).catch((error) => {
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying... (${retryCount}/${maxRetries})`);
          executePromise();
        } else {
          reject(error);
        }
      });
    }

    executePromise();
  });
}

export function waitForSeconds (time) {
  const _time = time * 1000;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, _time);
  });
}

export async function preLoadImage (path) {
  return new Promise((resolve, reject) => {
    const images = new Image();
    images.addEventListener('load', () => {
      resolve();
    });
    images.addEventListener('error', (e) => {
      reject(e);
    });
    images.src = path;
  });
}

export function deviceOS () {
  const agent = navigator.userAgent.toLowerCase();
  let type = 'windows';
  if ((/android/i).test(agent)) {
    type = 'android';
  } else if ((/ipad|iphone|macintosh/).test(agent)) {
    type = 'ios';
  }
  return type;
}

export function userInfo () {
  const agent = ua(navigator.userAgent);
  const accountId = store.getters['auth/accountId'] || '';
  const uniqueName = store.getters['profile/uniqueName'] || '';
  const device = `${agent.device.vendor} ${agent.device.model}`;
  const os = `${agent.os.name}(${agent.os.version})`;
  return {
    os: os,
    device: device,
    uniqueName: uniqueName,
    accountId: accountId
  };
}

export function countGrapheme (string) {
  const lang = navigator.language;
  const segmenter = new Intl.Segmenter(lang, { granularity: 'grapheme' });
  return [...segmenter.segment(string)].length;
}

// Unityのフォントが対応する文字のユニコード一覧に含まれているか検証
export function includeCode (str) {
  let res = true;
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    res = runeMap[code];
    if (!res) {
      break;
    }
  }
  return res;
}

export function setStaticSourceUrl (name) {
  let path = '';
  if (!name) return path;
  if (name.includes('https://')) {
    path = name;
  } else if (name.includes('textures')) {
    path = `${STATIC_URL}${name}`;
  } else if (name[0] === '/') {
    path = `${STATIC_URL}/textures${name}`;
  } else {
    path = `${STATIC_URL}/textures/${name}`;
  }
  return path;
}
