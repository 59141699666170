import Vue from 'vue';
import Vuex from 'vuex';
import header from '@/store/modules/header';
import loading from '@/store/modules/loading';
import sidebar from '@/store/modules/sidebar';
import auth from '@/store/modules/auth';
import reset from '@/store/modules/reset';
import profile from '@/store/modules/profile';
import event from '@/store/modules/event';
import episode from '@/store/modules/episode';
import bbs from '@/store/modules/bbs';
import loginAlert from '@/store/modules/loginAlert';
import serialcode from '@/store/modules/serialcode';
import user from '@/store/modules/user';
import notification from '@/store/modules/notification';
import ticket from '@/store/modules/ticket';
import purchase from '@/store/modules/purchase';
import purchaseModal from '@/store/modules/purchaseModal';
import ip from '@/store/modules/ip';
import screen from '@/store/modules/screen';
import home from '@/store/modules/home';
import background from '@/store/modules/background';
import post from '@/store/modules/post/index';
import story from '@/store/modules/story';
import time from '@/store/modules/time';
import coin from '@/store/modules/coin';
import theme from '@/store/modules/theme';
import follow from '@/store/modules/follow';
import collection from '@/store/modules/collection';
import address from '@/store/modules/address';
import gacha from '@/store/modules/gacha';
import shop from '@/store/modules/shop';
import currency from '@/store/modules/currency';
import tutorial from '@/store/modules/tutorial';
import maintenance from '@/store/modules/maintenance';
import report from '@/store/modules/report';
import block from '@/store/modules/block';
import device from '@/store/modules/device';
import present from '@/store/modules/present';
import offer from '@/store/modules/offer';
import video from '@/store/modules/video';
import special from '@/store/modules/special';
import analytics from '@/store/modules/analytics';
import livetalk from '@/store/modules/livetalk/index';
import talk from '@/store/modules/talk';
import vote from '@/store/modules/vote';
import lottery from '@/store/modules/lottery';

import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loading,
    sidebar,
    header,
    auth,
    reset,
    profile,
    event,
    loginAlert,
    serialcode,
    episode,
    bbs,
    user,
    notification,
    ticket,
    purchase,
    purchaseModal,
    ip,
    screen,
    home,
    background,
    post,
    story,
    time,
    coin,
    theme,
    follow,
    collection,
    address,
    gacha,
    shop,
    currency,
    tutorial,
    maintenance,
    report,
    block,
    device,
    present,
    offer,
    video,
    special,
    analytics,
    livetalk,
    talk,
    vote,
    lottery
  },
  plugins: [
    createPersistedState({
      key: 'pipmeyApp',
      paths: ['ip.visited', 'ip.onlyOfficial'],
      storage: window.localStorage
    })
  ]
});
