export const FooterNavItems = function() {
  return [
    {
      link: 'https://10antz.co.jp',
      name: '運営会社'
    },
    {
      key: 'SPECIFIED_COMMERCIAL_TRANSACTIONS',
      name: '特定商取引法に基づく表記'
    },
    {
      key: 'REGISTER_TERMS',
      name: '利用規約'
    },
    {
      key: 'PRIVACY_POLICY',
      name: 'プライバシーポリシー'
    },
    {
      key: 'COMMUNICATION_GUIDELINE',
      name: 'コミュニティガイドライン'
    },
    {
      to: '/account/faq',
      name: 'よくある質問'
    },
    {
      to: '/account/contact',
      name: 'お問い合わせ'
    }
  ];
};

