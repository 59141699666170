import axios from 'axios';
import { BASE_URL } from '@/config/const';

class TicketAPI {
  async search(payload) {
    return await axios.get(`${BASE_URL}/v1/viewing/purchase`, { params: payload });
  }
}

export default new TicketAPI();


