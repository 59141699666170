import axios from 'axios';
import { BASE_URL } from '@/config/const';

class TutorialAPI {
  async tips (payload) {
    return await axios.get(`${BASE_URL}/v2/tutorial/tips/${payload.type}`, { params: { ip_id: payload.ipId } });
  }

  async complete (tutorial_id) {
    return await axios.post(`${BASE_URL}/v2/tutorial`, { tutorial_id });
  }
}

export default new TutorialAPI();


