import photo from '@/store/modules/vote/ranking/bonus/photo';
import VoteRankingAPI from '@/api/vote/ranking';

// initial state
const state = () => ({
  bonus: null,
  achievedRatePercent: 0,
  ownedBonusPhotoList: []
});

// getters
const getters = {
  bonus: (state) => state.bonus,
  achievedRatePercent: (state) => state.achievedRatePercent,
  ownedBonusPhotoList: (state) => state.ownedBonusPhotoList
};

// actions
const actions = {

  async bonus({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.bonus(id);
      state.bonus = res.data?.bonus || null;
      state.achievedRatePercent = res.data?.achievedRatePercent || 0;
      state.ownedBonusPhotoList = res.data?.ownedBonusPhotoList || [];
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    photo
  }
};
