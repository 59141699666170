import axios from 'axios';
import { BASE_URL } from '@/config/const';
import Cookies from 'js-cookie';
import { convertZuluTime } from '@/util';

class ProfileAPI {
  async create(payload) {
    payload.birthday = convertZuluTime(payload.birthday);
    return await axios.post(`${BASE_URL}/v2/profile`, payload);
  }

  async get(payload) {
    const token = Cookies.get('token');
    if (token) {
      return await axios.get(`${BASE_URL}/v2/profile`, { silent: true });
    }
    return false;
  }
}

export default new ProfileAPI();


