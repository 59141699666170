import axios from 'axios';
import { BASE_URL } from '@/config/const';

class BlockAPI {
  async create(payload) {
    return await axios.post(`${BASE_URL}/v2/block`, payload);
  }

  async cancel(payload) {
    return await axios.delete(`${BASE_URL}/v2/block`, { data: payload });
  }

  async get() {
    return await axios.get(`${BASE_URL}/v2/block`);
  }
}

export default new BlockAPI();


