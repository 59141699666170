import HomeAPI from '@/api/home';
import cloneDeep from 'lodash/cloneDeep';

// initial state
const state = () => ({
  home: null,
  photos: {
    photos: [],
    seek: null
  }
});

// getters
const getters = {
  home: (state) => state.home,
  photos: (state) => state.photos
};

// actions
const actions = {
  async get({ state, commit }, id) {
    try {
      const res = await HomeAPI.get();
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async selectPhoto({ state, commit }, payload) {
    try {
      await HomeAPI.selectPhoto(payload);
    } catch (e) {
      throw e;
    }
  },
  async photos({ state, commit }, payload) {
    try {
      if (payload.params.type === 'get') {
        state.photos = {
          photos: [],
          seek: null
        };
      }
      const _payload = cloneDeep(payload);
      delete payload.params.type;
      const res = await HomeAPI.photos(payload);
      if (res.data) {
        if (_payload.params.type === 'add') {
          commit('addPhotos', res.data);
        } else {
          commit('setPhotos', res.data);
        }
      }
    } catch (e) {
      throw e;
    }
  },
  async unselectPhoto({ state, commit }, id) {
    try {
      await HomeAPI.unselectPhoto(id);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.home = data;
  },
  setPhotos(state, data) {
    state.photos.photos = data.photoList;
    const lastData = data.photoList.slice(-1)[0];
    state.photos.seek = { hasNext: data.hasNext, lastPhotoId: lastData?.photoId };
  },
  addPhotos(state, data) {
    const _photos = cloneDeep(state.photos);
    _photos.photos.push(...data.photoList);
    const lastData = data.photoList.slice(-1)[0];
    _photos.seek = { hasNext: data.hasNext, lastPhotoId: lastData.photoId };
    state.photos = _photos;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
