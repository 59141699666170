import timeline from '@/store/modules/post/timeline';
import comment from '@/store/modules/post/comment';

export default {
  modules: {
    post: {
      namespaced: true,
      modules: {
        timeline,
        comment
      }
    }
  }
};
