import EpisodeBbsAPI from '@/api/bbs';

// initial state
const state = () => ({
  data: null
});

// getters
const getters = {
  get: (state) => state.data
};

// actions
const actions = {
  async get({ commit }, payload) {
    try {
      const res = await EpisodeBbsAPI.get(payload);
      if (res) {
        commit('setData', res.data.data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setData(state, data) {
    state.data = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
