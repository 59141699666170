// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkInfoAPI from '@/api/livetalk/info';

const state = () => ({
  info: {}
});

// getters
const getters = {
  info: (state) => state.info
};

// actions
const actions = {
  async get({ state, commit }, { livetalkId }) {
    try {
      const res = await LiveTalkInfoAPI.get(livetalkId);
      if (res.data) {
        state.info = res.data;
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.address = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
