import Vue from 'vue';
import moment from 'moment';

Vue.directive('date', function(el, binding) {
  if (!binding.value) return '';
  let value = '';
  let format = binding.modifiers.datetime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD';
  if (binding.modifiers.ja) {
    format = binding.modifiers.datetime ? 'YYYY年MM月DD日 HH:mm' : 'YYYY年MM月DD日';
  }
  const isRange = binding.modifiers.range || false;
  if (isRange) {
    value = `${moment(binding.value.start).format(format)} ~ ${moment(binding.value.end).format(format)}`;
  } else {
    value = moment(binding.value).format(format);
  }
  el.innerHTML = value;
});
