import axios from 'axios';
import { BASE_URL } from '@/config/const';

class CollectionAPI {
  async photo(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/photo`, { params: payload });
  }

  async historyStory(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/story/group/history`, { params: payload });
  }

  async availableStory(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/story/group/available`, { params: payload });
  }

  async privilegeStory(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/story/group/privilege`, { params: payload });
  }

  async privilegeEpisode(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/story/group/privilege/${payload.storyPrivilegeGroupId}`, { params: payload.query });
  }

  async movie(payload) {
    // 特典ムービー一覧
    return await axios.get(`${BASE_URL}/v2/collection/movie`, { params: payload });
  }

  async historyMovies(payload) {
    // 履歴ムービー一覧
    return await axios.get(`${BASE_URL}/v2/collection/video/ondemand`, { params: payload });
  }

  async specialList(payload) {
    // スペシャル一覧
    return await axios.get(`${BASE_URL}/v2/collection/special`, { params: payload });
  }

  async ticketList(payload) {
    // チケット一覧
    return await axios.get(`${BASE_URL}/v2/collection/special/privilege`, { params: payload });
  }

  async ticket(id) {
    // チケット詳細
    return await axios.get(`${BASE_URL}/v2/collection/special/privilege/${id}`);
  }

  async setAddressInfo(payload) {
    return await axios.post(`${BASE_URL}/v2/collection/special/privilege/address`, payload);
  }

  async getAddressInfo(payload) {
    return await axios.get(`${BASE_URL}/v2/collection/special/privilege/address`, { params: payload });
  }

  async lotteryList(payload) {
    // オンラインくじ一覧
    return await axios.get(`${BASE_URL}/v2/collection/lottery`, { params: payload });
  }
}

export default new CollectionAPI();
