import axios from 'axios';
import { BASE_URL } from '@/config/const';

class PresentAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/present`, { params: payload });
  }

  async history() {
    return await axios.get(`${BASE_URL}/v2/present/history`);
  }

  async receive(payload) {
    return await axios.put(`${BASE_URL}/v2/present/receive`, payload);
  }
}

export default new PresentAPI();


