import axios from 'axios';
import { BASE_URL } from '@/config/const';

class LiveTalkGroupMemberAPI {
  async get(groupId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/member/group/${groupId}/livetalk`);
  }
}

export default new LiveTalkGroupMemberAPI();


