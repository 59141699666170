import axios from 'axios';
import { BASE_URL } from '@/config/const';

class PostAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}`, { params: payload.query, disableLoad: true });
  }

  async get(payload) {
    return await axios.get(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}/${payload.postId}`, { silent: true });
  }

  async create(payload) {
    return await axios.post(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}`, payload.payload);
  }

  async edit(payload) {
    return await axios.put(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}/${payload.postId}`, payload.payload);
  }

  async delete(payload) {
    return await axios.delete(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}/${payload.postId}`);
  }

  async media(payload) {
    const config = {
      timeout: 50000,
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return await axios.post(`${BASE_URL}/http/v2/post/media`, payload, config);
  }

  async ogp(query) {
    return await axios.get(`${BASE_URL}/v2/ogp/crawl`, { params: query });
  }

  async like(payload) {
    return await axios.post(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}/${payload.postId}/like`);
  }

  async deleteLike(payload) {
    return await axios.delete(`${BASE_URL}/v2/post/${payload.type}/${payload.typeId}/${payload.postId}/like`);
  }
}

export default new PostAPI();


