import axios from 'axios';
import { BASE_URL } from '@/config/const';

class IPAPI {
  async list(params) {
    return await axios.get(`${BASE_URL}/v2/ip`, { params: params });
  }

  async get(id) {
    console.warn('/----- api get id : ', id);
    return await axios.get(`${BASE_URL}/v2/ip/${id}`);
  }

  async getNoLogin(id) {
    return await axios.get(`${BASE_URL}/v2/ip/nologin/${id}`);
  }

  async overview(id) {
    return await axios.get(`${BASE_URL}/v2/ip/overview/${id}`);
  }

  async follow(id) {
    return await axios.post(`${BASE_URL}/v2/follow/ip/${id}`);
  }

  async unfollow(id) {
    return await axios.delete(`${BASE_URL}/v2/follow/ip/${id}`);
  }
}

export default new IPAPI();


