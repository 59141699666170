import axios from 'axios';
import { BASE_URL } from '@/config/const';

class AnalyticsAPI {
  async videoStart(payload) {
    return await axios.post(`${BASE_URL}/v2/analytics/start/playing/video`, payload);
  }

  async videoEnd(payload) {
    return await axios.post(`${BASE_URL}/v2/analytics/finish/playing/video`, payload);
  }

  async privilegeMovieStart(payload) {
    return await axios.post(`${BASE_URL}/v2/analytics/start/playing/movie`, payload);
  }

  async privilegeMovieEnd(payload) {
    return await axios.post(`${BASE_URL}/v2/analytics/finish/playing/movie`, payload);
  }
}

export default new AnalyticsAPI();


