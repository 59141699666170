import EpisodeAPI from '@/api/episode';

// initial state
const state = () => ({
  list: [],
  data: null,
  userCount: 0,
  ondemandVideo: null,
  pagination: null
});

// getters
const getters = {
  get: (state) => state.data,
  getPagination: (state) => state.pagination,
  getUserCount: (state) => state.userCount,
  getOndemandVideo: (state) => state.ondemandVideo
};

// actions
const actions = {
  async get({ commit }, payload) {
    try {
      const res = await EpisodeAPI.get(payload);
      if (res) {
        commit('setData', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async getOndemandVideoUrl({ commit }, payload) {
    try {
      const res = await EpisodeAPI.getOndemandVideoUrl(payload);
      if (res) {
        commit('setOndemandVideo', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async count({ commit }, payload) {
      try {
        const res = await EpisodeAPI.count(payload);
        if (res) {
          commit('setUserCount', res.data.value);
        }
      } catch (e) {
        throw e;
      }
  }
};

const mutations = {
  setData(state, data) {
    state.data = data.data;
    state.pagination = data.pagination;
  },
  setUserCount(state, data) {
    state.userCount = data;
  },
  setOndemandVideo(state, data) {
    state.ondemandVideo = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
