import SerialCodeAPI from '@/api/serialcode';

// initial state
const state = () => ({
  list: [],
  errors: [],
  data: null
});

// getters
const getters = {
  getList: (state) => state.list,
  getError: (state) => state.errors,
  get: (state) => state.data
};

// actions
const actions = {
  async entry({ commit }, codes) {
    try {
      const res = await SerialCodeAPI.entry(codes);
      if (res) {
        commit('setList', res.data.data);
        commit('setError', res.data.errors);
      }
    } catch (e) {
      throw e;
    }
  },
  async register({ commit }, codes) {
    try {
      await SerialCodeAPI.register(codes);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },
  setError(state, data) {
    state.errors = data;
  },
  setData(state, data) {
    state.data = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
