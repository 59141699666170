import axios from 'axios';
import { BASE_URL } from '@/config/const';

class LotteryAPI {
  async get(id) {
    return await axios.get(`${BASE_URL}/v2/lottery/${id}`);
  }

  async purchase({ id, payload }) {
    return await axios.post(`${BASE_URL}/v2/lottery/${id}/buy`, payload, { silent: true });
  }

  async select({ id, payload }) {
    return await axios.put(`${BASE_URL}/v2/collection/lottery/${id}/acc_item/select`, payload);
  }
}

export default new LotteryAPI();


