<template>
  <div class="not-found">
    <img class="not-found-img" src="@/assets/images/404.png">
    <p class="not-found-title">ページが見つかりませんでした</p>
    <p class="not-found-text">申し訳ありません、お探しのページは見つかりませんでした。<br>URLが問違っているか、削除もしくは移動された可能性があります。</p>
    <CommonButton color="red" medium @click="onClickTop">TOPへ</CommonButton>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  computed: {
    isLogin() {
      return this.$store.getters['auth/isLogin'];
    }
  },
  methods: {
    onClickTop() {
      if (this.isLogin) {
        this.$router.replace('/home');
      } else {
        this.$router.replace('/top');
      }
    }
  }
};
</script>


<style scoped lang="scss">
.not-found {
  background: #f5f5f5;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 565px;
    margin-bottom: 70px;
  }
  .not-found-title {
    font-size: 3.2rem;
    line-height: 1;
    margin-bottom: 24px;
  }
  .not-found-text {
    font-size: 2rem;
    margin-bottom: 64px;
    text-align: center;
  }
}
@media screen and (max-width: 1023px) {
  .not-found {
    img {
      width: 282px;
      margin-bottom: 34px;
    }
    .not-found-title {
      font-size: 1.6rem;
      line-height: 1;
      margin-bottom: 12px;
    }
    .not-found-text {
      font-size: 1.2rem;
      margin: 0 55px 32px;
      text-align: center;
    }
  }
}
</style>
