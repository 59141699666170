const state = () => ({
  show: false,
  id: ''
});

const getters = {
  isShow: (state) => state.show,
  id: (state) => state.id
};

const mutations = {
  open(state, id) {
    state.show = true;
    state.id = id;
  },
  close(state) {
    state.show = false;
    state.id = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
