// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkReserveAPI from '@/api/livetalk/reserve';

const state = () => ({
  data: null
});

// getters
const getters = {
  data: (state) => state.data
};

// actions
const actions = {
  async get({ state, commit }, { accTicketId, livetalkId }) {
    try {
      const res = await LiveTalkReserveAPI.get(accTicketId, livetalkId);
      if (res.data) {
        state.data = res.data;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
