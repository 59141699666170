<template>
  <transition name="fade">
    <div class="loading" v-if="this.show">
      <img src="@/assets/images/loading.svg" class="loading-img">
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loading',
  computed: {
    show() {
      return this.$store.state.loading.show;
    }
  }
};
</script>

<style scoped lang="scss">
  .loading {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff63;
    top: 0;
    bottom: 0;

    img {
      width: 80px;
    }
  }

  // transition fade
  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .3s;
  }
  .fade-enter-active {
    transition-delay: .3s;
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
</style>
