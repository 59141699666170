import VoteRankingAPI from '@/api/vote/ranking';

// initial state
const state = () => ({
  totalVotedAmount: 0,
  rewardList: [],
});

// getters
const getters = {
  totalVotedAmount: (state) => state.totalVotedAmount,
  rewardList: (state) => state.rewardList
};

// actions
const actions = {

  async reward({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.userReward(id);
      state.totalVotedAmount = res.data?.totalVotedAmount || 0;
      state.rewardList = res.data?.rewardList || [];
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
