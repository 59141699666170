<template>
  <CustomModal
    id="remoteConfigModal"
    class="remote-config-modal"
    full
    stretch
    bg="white"
    v-if="isShow"
    @close="close"
  >
    <template v-slot:header>
      <span class="header-title">{{ title }}</span>
    </template>
    <template v-slot:body>
      <div class="notes-content">
        <p>{{body}}</p>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/CustomModal';
import resolution from '@/mixin/resolution';
import Firebase from '@/api/firebase';

export default {
  name: 'RemoteConfigModal',
  components: {
    CustomModal
  },
  mixins: [resolution],
  computed: {},
  data() {
    return {
      title: '',
      body: '',
      isShow: false
    };
  },
  methods: {
    async open(title, key) {
      this.title = title;
      const config = await Firebase.getConfigValue(key);
      this.body = config._value;
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
::v-deep {
  .modal-inner {
    .modal-body {
      overflow-x: scroll;
      .notes-content {
        white-space: pre-wrap;
      }
    }
  }
}
</style>
