// import CoinAPI from '@/api/coin';

// initial state
import AddressAPI from '@/api/address';

const state = () => ({
  temp: null,
  address: null,
  ticketAddress: null
});

// getters
const getters = {
  get: (state) => state.address,
  getTemp: (state) => state.temp
};

// actions
const actions = {
  async get({ state, commit }) {
    try {
      const res = await AddressAPI.get();
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async create({ state, commit }, payload) {
    try {
      const res = await AddressAPI.create(payload);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  setTempAddress({ state, commit }, payload = null) {
    commit('setTemp', payload);
  },
  reset({ commit }) {
    try {
      commit('set', null);
      commit('setTemp', null);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  set(state, data) {
    state.address = data;
  },
  setTemp(state, data) {
    state.temp = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
