import ranking from '@/store/modules/vote/ranking/ranking';
import bonus from '@/store/modules/vote/ranking/bonus';
import reward from '@/store/modules/vote/ranking/reward';
export default {
  namespaced: true,
  modules: {
    ranking,
    bonus,
    reward
  }
};
