<template>
  <div v-if="!isHideFooter" ref="footer" id="footer" :class="(isLogin && !isShow) ? 'fixed-footer' : ''">
    <template v-if="isSP">
      <SP v-if="device === 'web'" :footer_type="footer_type" />
    </template>
    <template v-else>
      <PC v-if="isShow" :footer_type="footer_type" />
    </template>
  </div>
</template>

<script>
import { FOOTER_TYPE } from '@/config/const';
import PC from './PC';
import SP from './SP';


export default {
  name: 'Footer',
  components: {
    PC,
    SP
  },
  computed: {
    isSP () {
      return this.$store.getters['screen/isSP'];
    },
    isLogin () {
      return this.$store.getters['auth/isLogin'];
    },
    hasProfile () {
      return this.$store.getters['auth/hasProfile'];
    },
    device () {
      return this.$store.getters['device/get'];
    },
    isHideFooter () {
      if (this.isSP && (this.$route.matched.some((record) => record.name === 'gacha-top'))) {
       return true;
      } else {
        return this.device === 'unity' || !!this.$route.meta.hideFooter;
      }
    }
  },
  watch: {
    isSP () {
      this.$nextTick(() => {
        this.$store.dispatch('screen/footer', this.$refs.footer?.clientHeight);
      });
    },
    $route () {
      this.init();
    }
  },
  data () {
    return {
      footer_type: FOOTER_TYPE.FULL,
      isShow: false
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.footer_type = this.$route.meta?.footer_type || FOOTER_TYPE.FULL;
      this.$store.dispatch('device/set', this.$route.query?.device || 'web');
      if (this.isLogin) {
        if (this.hasProfile) {
          this.isShow = false;
          if (this.$route.matched.some((record) => record.name === 'signup-follow' || record.name === 'profile-confirm' || record.name === 'profile-complete')) {
            this.isShow = true;
            this.footer_type = FOOTER_TYPE.FULL;
          }
        } else {
          this.isShow = true;
          this.footer_type = FOOTER_TYPE.FULL;
        }
        if (!this.isHideFooter) {
          this.$store.dispatch('screen/footer', this.$refs.footer?.clientHeight);
        }
      } else {
        this.isShow = this.footer_type === FOOTER_TYPE.FULL;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fixed-footer {
  position: fixed;
  top: auto;
  left: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
}
</style>

