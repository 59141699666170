import PostAPI from '@/api/post';
import cloneDeep from 'lodash/cloneDeep';

// initial state
const state = () => ({
  list: [],
  children: [],
  seek: null
});

// getters
const getters = {
  list: (state) => state.list,
  children: (state) => (id) => state.children.find((child) => child.id === id),
  seek: (state) => state.seek
};

// actions
const actions = {
  async list ({ state, commit }, payload = { silent: false }) {
    try {
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('setList', res.data.list);
        state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async next ({ state, commit }, payload = { silent: false }) {
    try {
      payload.query['seek.lastCreateTime'] = state.seek?.lastCreateTime;
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('addList', res.data.list);
        state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async create ({ state, commit }, data = { silent: false }) {
    try {
      const res = await PostAPI.create(data);
      commit('addComment', res.data);
    } catch (e) {
      throw e;
    }
  },
  async edit ({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.edit(data);
      commit('edit', data);
    } catch (e) {
      throw e;
    }
  },
  async delete ({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.delete(data);
      commit('delete', data);
    } catch (e) {
      throw e;
    }
  },
  async childEdit ({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.edit(data);
      commit('childEdit', data);
    } catch (e) {
      throw e;
    }
  },
  async deleteChild ({ state, commit }, data = { silent: false }) {
    try {
      await PostAPI.delete(data);
      commit('deleteChild', data);
    } catch (e) {
      throw e;
    }
  },
  async createReply ({ state, commit }, data = { silent: false }) {
    try {
      const res = await PostAPI.create(data);
      commit('addReply', res.data);
    } catch (e) {
      throw e;
    }
  },
  async reply ({ state, commit }, payload = { silent: false }) {
    try {
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('setReply', { id: payload.postId, data: res.data });
        // state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  async nextReply ({ state, commit }, payload = { silent: false }) {
    try {
      const res = await PostAPI.list(payload);
      if (res.data) {
        commit('nextReply', { id: payload.postId, data: res.data });
        // state.seek = res.data.seek;
      }
    } catch (e) {
      throw e;
    }
  },
  reset ({ state }) {
    state.list = [];
    state.children = [];
    state.seek = null;
  }
};

const mutations = {
  addComment (state, data) {
    const list = cloneDeep(state.list);
    list.unshift(data);
    state.list = list;
  },
  addReply (state, data) {
    const children = cloneDeep(state.children);
    const child = children.find((child) => child.id === data.parentPostId);
    if (child) {
      child.list.unshift(data);
    } else {
      children.unshift({
        id: data.parentPostId,
        list: [data],
        seek: null
      });
    }
    state.children = children;
  },
  setList (state, data) {
    data.forEach((item) => {
      item.childrenCount = parseInt(item.childrenCount, 10);
    });
    state.list = data;
  },
  addList (state, data) {
    data.forEach((item) => {
      item.childrenCount = parseInt(item.childrenCount, 10);
    });
    if (state.list.length > 0) {
      state.list = [...state.list, ...data];
    } else {
      state.list = data;
    }
  },
  setReply (state, data) {
    const children = cloneDeep(state.children);
    const child = children.find((child) => child.id === data.id);
    if (child) {
      child.seek = data.data.seek;
      child.list = data.data.list;
    } else {
      children.push({
        id: data.id,
        list: data.data.list,
        seek: data.data.seek
      });
    }
    state.children = children;
  },
  nextReply (state, data) {
    const children = cloneDeep(state.children);
    const child = children.find((child) => child.id === data.id);
    if (child) {
      child.seek = data.data.seek;
      child.list = [...child.list, ...data.data.list];
    } else {
      children.push({
        id: data.id,
        list: data.data.list,
        seek: data.data.seek
      });
    }
    state.children = children;
  },
  edit (state, data) {
    console.log(data);
    const index = state.list.findIndex((post) => post.postId === data.postId);
    if (index >= 0) {
      const list = cloneDeep(state.list);
      list[index].text = data.payload.text;
      state.list = list;
    }
  },
  delete (state, data) {
    const index = state.list.findIndex((post) => post.postId === data.postId);
    if (index >= 0) {
      const list = cloneDeep(state.list);
      list.splice(index, 1);
      state.list = list;
    }
  },
  childEdit (state, data) {
    const children = cloneDeep(state.children);
    const child = children.find((child) => child.id === data.parentPostId);
    if (child) {
      const index = child.list.findIndex((_child) => _child.id === data.id);
      if (index >= 0) {
        const list = cloneDeep(child.list);
        list[index].text = data.payload.text;
        child.list = list;
        state.children = children;
      }
    }
  },
  deleteChild (state, data) {
    const children = cloneDeep(state.children);
    const child = children.find((child) => child.id === data.parentPostId);
    if (child) {
      const index = child.list.findIndex((_child) => _child.id === data.id);
      if (index >= 0) {
        const list = cloneDeep(child.list);
        list.splice(index, 1);
        child.list = list;
        state.children = children;
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
