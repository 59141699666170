import AuthAPI from '@/api/auth';
import Cookies from 'js-cookie';

// initial state
const state = () => ({
  auth: null
});

// getters
const getters = {
  getAuth: (state) => state.auth
};

// actions
const actions = {
  async saveEmailPrepare({ commit }, email) {
    try {
      await AuthAPI.saveEmailPrepare(email);
    } catch (e) {
      throw e;
    }
  },
  async saveEmailCode({ commit }, code) {
    try {
      await AuthAPI.saveEmailCode(code);
    } catch (e) {
      throw e;
    }
  },
  async resetPasswordPrepare({ commit }, payload) {
    try {
      await AuthAPI.resetPasswordPrepare(payload);
      Cookies.set('reset-email', payload.email);
    } catch (e) {
      throw e;
    }
  },
  async resetPasswordCode({ commit }, payload) {
    try {
      await AuthAPI.resetPasswordCode(payload);
      Cookies.set('pin-code-cache', payload.code);
    } catch (e) {
      throw e;
    }
  },
  async resetPassword({ commit }, payload) {
    try {
      await AuthAPI.resetPassword(payload);
      Cookies.remove('pin-code-cache');
      Cookies.remove('reset-email');
    } catch (e) {
      Cookies.remove('pin-code-cache');
      Cookies.remove('reset-email');
      throw e;
    }
  },
  async savePasswordPrepare({ commit }) {
    try {
      await AuthAPI.savePasswordPrepare();
    } catch (e) {
      throw e;
    }
  },
  async savePasswordCode({ commit }, code) {
    try {
      await AuthAPI.savePasswordCode(code);
    } catch (e) {
      throw e;
    }
  },
  async savePassword({ commit }, password) {
    try {
      await AuthAPI.savePassword(password);
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setAuth(state, data) {
    state.auth = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
