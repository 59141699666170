import AnalyticsAPI from '@/api/analytics';

// actions
const actions = {
  async videoStart({ state, commit }, payload) {
    try {
      await AnalyticsAPI.videoStart(payload);
    } catch (e) {
      throw e;
    }
  },
  async videoEnd({ state, commit }, payload) {
    try {
      await AnalyticsAPI.videoEnd(payload);
    } catch (e) {
      throw e;
    }
  },
  async privilegeMovieStart({ state, commit }, payload) {
    try {
      await AnalyticsAPI.privilegeMovieStart(payload);
    } catch (e) {
      throw e;
    }
  },
  async privilegeMovieEnd({ state, commit }, payload) {
    try {
      await AnalyticsAPI.privilegeMovieEnd(payload);
    } catch (e) {
      throw e;
    }
  }
};


export default {
  namespaced: true,
  actions
};
