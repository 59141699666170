import OfferAPI from '@/api/offer';

// initial state
const state = () => ({
  list: [],
  room: null,
  item: null,
  favor: null
});

// getters
const getters = {
  list: (state) => state.list,
  room: (state) => state.room,
  item: (state) => state.item,
  favor: (state) => state.favor
};

// actions
const actions = {
  async list({ state, commit }, payload = null) {
    try {
      const res = await OfferAPI.list(payload);
      if (res.data) {
        commit('setList', res.data.list);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, id) {
    try {
      const res = await OfferAPI.get(id);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async item({ state, commit }, id) {
    try {
      const res = await OfferAPI.item(id);
      if (res.data) {
        commit('item', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async favorite({ state, commit }, payload) {
    try {
      await OfferAPI.favorite(payload);
    } catch (e) {
      throw e;
    }
  },
  async favor({ state, commit }, payload) {
    const res = await OfferAPI.favor(payload);
    if (res) {
      commit('favor', res.data);
    } else {
      try {
        commit('favor', res.data);
      } catch (e) {
        throw e;
      }
    }
  }
};

const mutations = {
  set(state, data) {
    state.room = data;
  },
  setList(state, data) {
    state.list = data;
  },
  item(state, data) {
    state.item = data;
  },
  favor(state, payload) {
    state.favor = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
