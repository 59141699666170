<template>
  <button
      :class="[
          'btn',
          classes,
          color,
          round ? 'round' : '',
          outline ? 'outline' : '',
          full ? 'full' : '',
          xSmall ? 'x-small' : '',
          small ? 'small' : '',
          medium ? 'medium' : '',
          xMedium ? 'x-medium' : '',
          large ? 'large' : '',
          flat ? 'flat' : ''
      ]"
      :style="[styles, { width: width, height: height }]"
      @click="$emit('click')"
      :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CommonButton',
  props: {
    classes: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean
    },
    outline: {
      type: Boolean
    },
    full: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    flat: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    xMedium: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 0px;
  height: 64px;
  width: 512px;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
  background: $baseLight;
  color: $black;
  &.small {
    height: 32px;
    width: 116px;
    font-size: 1.4rem;
  }
  &.x-small {
    height: 40px;
    font-size: 1.6rem;
    //padding: 0 30px;
    width: 161px;
  }
  &.medium {
    height: 62px;
    width: 256px;
    font-size: 2rem;
  }
  &.x-medium {
    height: 64px;
    width: 400px;
    font-size: 2rem;
  }
  &.large {
    height: 63px;
    width: 512px;
    font-size: 2rem;
  }
  &::before {
    display: block;
    content: '';
    height: 400%;
    width: 200%;
    background: white;
    position: absolute;
    top: -346%;
    border-radius: 100%;
    opacity: 0.15;
  }
  &.primary {
    background: $yellow;
  }
  &.blue {
    color: $white;
    background: $blue;
  }
  &.gray {
    background: $baseMiddle;
  }
  &.purple {
    color: $white;
    background: $purple !important;
  }
  &.green {
    color: $white;
    background: $green;
  }
  &.red {
    color: $white;
    background: $red;
  }
  &:disabled {
    opacity: 0.5;
    &.outline {
      opacity: 0.3;
    }
    &::after {
      display: block;
      content: '';
      height: 100%;
      width: 100%;
      //background: white;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &.round {
    border-radius: 50px;
  }
  &.outline {
    border: 1px solid $yellow;
    background: white;
    &.blue {
      color: $blue;
      border: 1px solid $blue;
    }
    &.green {
      color: $green;
      border: 1px solid $green;
    }
    &:disabled {
      //border: 1px solid $grayText !important;
      //background: transparent !important;
      //color: $grayText !important;
    }
    &.gray {
      border: 1px solid $baseBlack !important;
      background: transparent !important;
      color: $baseBlack !important;
    }
  }
  &.full {
    width: 100%;
    max-width: 100%;
  }
  &.flat {
    &:before {
      content: none;
    }
  }
}
@media screen and ( max-width:1023px) {
  .btn {
    padding: 13px 0px;
    height: 32px;
    width: 256px;
    font-size: 1.2rem;
    &.small {
      padding: 0;
      height: 20px;
      min-width: 66px;
      width: auto;
      padding: 0 8px;
      font-size: 1rem;
    }
    &.x-small {
      padding: 0;
      height: 28px;
      font-size: 1rem;
      //padding: 0 30px;
      width: 95px;
    }
    &.medium {
      height: 31px;
      width: 140px;
      font-size: 1rem;
    }
    &.x-medium {
      height: 31px;
      width: 200px;
      font-size: 1rem;
    }
    &.large {
      height: 31px;
      width: 256px;
      font-size: 1rem;
    }
    &::before {
      display: block;
      content: '';
      height: 400%;
      width: 200%;
      background: white;
      position: absolute;
      top: -346%;
      border-radius: 100%;
      opacity: 0.15;
    }
  }
}
</style>
