export default {
  data() {
    return {
      isIOS: false,
      isAndroid: false,
      isPC: false,
      isSafari: false,
      isUnity: false,
      deviceOS: ''
    };
  },
  created() {
    this.checkDevice();
    this.checkBrowser();
  },
  methods: {
    checkDevice() {
      if (this.$route.query?.device === 'unity') {
        this.isUnity = true;
        this.deviceOS = 'UNITY';
      }
      const ua = navigator.userAgent;
      if ((/android/i).test(ua)) {
        this.isAndroid = true;
        this.deviceOS = 'ANDROID';
      } else if ((/iPad|iPhone|iPod/).test(ua)) {
        this.isIOS = true;
        this.deviceOS = 'IOS';
      } else if ((/unity/i).test(ua)) {
        this.isUnity = true;
        this.deviceOS = 'UNITY';
      } else {
        this.isPC = true;
        this.deviceOS = 'WEB';
      }
    },
    checkBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('chrome') !== -1) {
        this.isSafari = false;
      } else if (ua.indexOf('safari') !== -1) {
        this.isSafari = true;
      }
    }
  }
};
