import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class LiveTalkReserveAPI {
  async get(accTicketId, livetalkId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/ticket/${accTicketId}/livetalk/${livetalkId}/slot`);
  }

  async reserve(accTicketId, livetalkId, slotId) {
    return await axios.post(`${BASE_URL}/v2/livetalk/ticket/${accTicketId}/livetalk/${livetalkId}/slot/${slotId}`);
  }

  async cancel(accTicketId, livetalkId, slotId) {
    return await axios.delete(`${BASE_URL}/v2/livetalk/ticket/${accTicketId}/livetalk/${livetalkId}/slot/${slotId}`);
  }
}

export default new LiveTalkReserveAPI();


