export const PROJECT_NAME = 'Pipmey';
export const COPYRIGHT = '© 2024 10ANTZ Inc.';

export const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const USER_API_BASE_URL = process.env.VUE_APP_USER_API_BASE_URL;
export const PAYMENT_API_BASE_URL = process.env.VUE_APP_PAYMENT_API_BASE_URL;
export const FAQ_URL = process.env.VUE_APP_FAQ_URL;
export const CONTACT_URL = process.env.VUE_APP_CONTACT_URL;
export const MANUAL_URL = process.env.VUE_APP_MANUAL_URL;
export const MERCHANT_ID = process.env.VUE_APP_PAYMENT_MERCHANT_ID;
export const SERVICE_ID = process.env.VUE_APP_PAYMENT_SERVICE_ID;
export const STATIC_URL = `${process.env.VUE_APP_STATIC_URL}`;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
export const YAHOO_ZIP_URL = process.env.VUE_APP_YAHOO_ZIP_API_URL;
export const YAHOO_CLIENT_ID = process.env.VUE_APP_YAHOO_CLIENT_ID;
export const APP_IOS_STORE_LINK = process.env.VUE_APP_IOS_STORE_LINK;
export const APP_ANDROID_STORE_LINK = process.env.VUE_APP_ANDROID_STORE_LINK;

export const HEADER_TYPE = {
  FULL: 1,
  SIMPLE: 2
};

export const FOOTER_TYPE = {
  FULL: 1,
  SIMPLE: 2
};

export const AUTH_PLATFORM = {
  APPLE: 'APPLE',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  GOOGLE: 'GOOGLE',
  LINE: 'LINE',
  EMAIL: 'EMAIL'
};

export const EPISODE_VIEW_TYPE = {
  FREE: 'FREE',
  PURCHASE: 'PURCHASE',
  SERIAL_CODE: 'SERIAL_CODE',
  UNSPECIFIED: 'UNSPECIFIED'
};

export const EPISODE_FORMAT_TYPE = {
  ON_DEMAND: 'ON_DEMAND',
  OVERLOOKING: 'OVERLOOKING',
  LINEAR: 'LINEAR',
  LIVE: 'LIVE'
};

export const GENDER_OPTIONS = [
  {
    label: '男性', value: '1'
  },
  {
    label: '女性', value: '2'
  },
  {
    label: '無回答', value: '3'
  }
];

export const PREFECTURES = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

export const PAYMENT_METHOD = {
  CREDIT_CARD: 'CREDIT_CARD',
  CONVENIENCE: 'CONVENIENCE',
  DOCOMO: 'DOCOMO',
  AU: 'AU',
  SOFT_BANK: 'SOFT_BANK',
  PAY_PAY: 'PAY_PAY',
  PERSISTENT_CARD: 'PERSISTENT_CARD'
};

export const PAYMENT_LABEL = {
  CREDIT_CARD: 'クレジットカード',
  CONVENIENCE: 'コンビニ払い',
  DOCOMO: 'ドコモ払い',
  AU: 'auかんたん決済',
  SOFT_BANK: 'ソフトバンクまとめて支払い',
  PAY_PAY: 'PayPay',
  PERSISTENT_CARD: 'クレジットカード'
};


export const TICKET_CONTENT = {
  GOODS: '1',
  REALEVENT: '2',
  LIVE: '3',
  PREMIER: '4',
  LIVETALK: '5'
};

export const TICKET_CONTENT_LABEL = {
  GOODS: 'グッズ',
  REALEVENT: 'リアルイベント',
  LIVE: 'ライブ',
  PREMIER: 'プレミア',
  LIVETALK: 'ライブトーク'
};

export const TICKET_CONTENT_TYPE = {
  GOODS: 'GOODS',
  REALEVENT: 'REALEVENT',
  LIVE: 'LIVE',
  PREMIER: 'PREMIER',
  LIVETALK: 'LIVETALK'
};


export const PHOTO_ATTACHMENT_TYPE = [
  {
    label: 'None', value: '1'
  },
  {
    label: 'Movie', value: '2'
  },
  {
    label: 'Voice', value: '3'
  },
  {
    label: 'Story', value: '4'
  },
  {
    label: 'MovieAndVoice', value: '5'
  },
  {
    label: 'MovieAndStory', value: '6'
  },
  {
    label: 'VoiceAndStory', value: '7'
  },
  {
    label: 'MovieAndVoiceAndStory', value: '8'
  }
];

export const TIPS_TYPE = {
  HOME_TITLE: 1,
  PROFILE_TITLE: 2,
  STORY: 3,
  HOME_EDIT: 4,
  GACHA: 5,
  OFFER: 6
};

export const DESTINATION_TYPE_OPTION = [
  { label: 'ガチャ一覧', name: 'gacha-top', params: 'ipId', query: 'gachaId', value: '1', isRequired: true },
  { label: 'お知らせ', name: 'notification-detail', params: 'id', value: '2', isRequired: true },
  { label: 'ショップ', value: '3', isRequired: false },
  { label: 'IP詳細', name: 'channel-detail', params: 'id', value: '4', isRequired: true },
  { label: 'ストーリー', name: 'story-detail', params: 'storyGroupId', value: '5', isRequired: true },
  { label: 'ストーリー一覧', name: 'channel-story-list', params: 'id', value: '6', isRequired: true },
  { label: 'ムービー詳細', name: 'movie-detail', params: 'videoId', value: '7', isRequired: true },
  { label: 'ムービー一覧', name: 'movie-top', params: 'id', value: '8', isRequired: true },
  { label: 'スペシャル(ムービー)', name: 'movie-detail', params: 'videoId', value: '9', isRequired: true },
  { label: 'スペシャル一覧', name: 'special-top', params: 'id', value: '10', isRequired: true },
  { label: '期間限定ミッション詳細', name: 'mission-detail', value: '11', isRequired: true },
  { label: '外部リンク', value: '12', isRequired: true },
  { label: 'スペシャル(ライブトーク)', name: 'livetalk-group', params: 'groupId', value: '13', isRequired: true }
];

export const CATEGORY_TYPE = {
  Notice: 'お知らせ',
  Defect: '不具合',
  Maintenance: 'メンテナンス',
  Campaign: 'キャンペーン',
  Update: 'アップデート',
  Gacha: 'ガチャ',
  NewContent: '追加コンテンツ'
};

export const ASSETS_TYPE_OPTION = [
  { value: '1', key: 'BUNDLE', label: 'バンドル' },
  { value: '2', key: 'CURRENCY', label: '通貨' },
  { value: '3', key: 'COIN', label: 'コイン' },
  { value: '4', key: 'ACHIEVEMENT', label: '称号' },
  { value: '5', key: 'BACKGROUND', label: '背景' },
  { value: '6', key: 'GOODS', label: 'グッズ' },
  { value: '7', key: 'PHOTO', label: 'フォト' },
  { value: '8', key: 'PRIVILEGE_MOVIE', label: '特典ムービー' },
  { value: '9', key: 'PRIVILEGE_STORY', label: '特典ストーリー' },
  { value: '10', key: 'TICKET', label: 'チケット' },
  { value: '11', key: 'REAL_EVENT', label: 'リアルイベント' },
  { value: '12', key: 'OFFER_ITEM', label: '奉納アイテム' },
  { value: '13', key: 'VOTE_POINT', label: '投票ポイント' }
];

export const STREAMING_TYPE = {
  NONE: 'NONE',
  ON_DEMAND: 'ONDEMAND',
  PREMIER: 'PREMIER',
  LIVE: 'LIVE',
  YOUTUBE: 'YOUTUBE'
};

export const STREAMING_TYPE_LABEL = {
  NONE: ' ',
  ONDEMAND: 'オンデマンド',
  PREMIER: 'プレミア',
  LIVE: 'ライブ',
  YOUTUBE: 'オンデマンド'
};

export const STREAMING_STATUS = {
  PREPARE: 0,
  START: 1,
  PAUSE: 2,
  RESTART: 3,
  END: 4,
  STOP: 5
};

export const VIDEO_BADGE = {
  VIDEO_BADGE_NEW: 'VIDEO_BADGE_NEW',
  VIDEO_BADGE_NONE: 'VIDEO_BADGE_NONE',
  VIDEO_BADGE_PICKUP: 'VIDEO_BADGE_PICKUP'
};

export const VIEWING_TYPE = {
  NONE: 'NONE',
  FREE: 'FREE',
  RENTAL: 'RENTAL',
  PURCHASE: 'PURCHASE',
  WEB_PAYMENT: 'WEB_PAYMENT',
  SERIAL_CODE: 'SERIAL_CODE',
  GACHA_PRIVILEGE: 'GACHA_PRIVILEGE'
};

export const AVAILABILITY_TYPE = {
  FREE: 'FREE',
  PURCHASE: 'PURCHASE',
  SERIALCODE: 'SERIALCODE',
  GACHA: 'GACHA',
  LIMITED: 'LIMITED',
  RENTAL: 'RENTAL'
};

export const HOLD_TYPE = {
  IN: 'IN_HOLDING',
  END: 'HOLDING_END'
};

export const CONTENT_TYPE = {
  LIVE: 'LIVE',
  PREMIER: 'PREMIER',
  LIVETALK: 'LIVETALK'
};

export const IDENTITY_VERIFICATION_STATUS = {
  PRE_REQUEST: 'PRE_REQUEST',
  IN_REVIEW: 'IN_REVIEW',
  RETURNED: 'RETURNED',
  VERIFIED: 'VERIFIED',
  DENIED: 'DENIED',
  IN_CHANGE_REVIEW: 'IN_CHANGE_REVIEW',
  CHANGE_RETURNED: 'CHANGE_RETURNED',
  CHANGE_VERIFIED: 'CHANGE_VERIFIED',
  CHANGE_DENIED: 'CHANGE_DENIED'
};

export const SPECIFIED_COMMERCIAL_TRANSACTION_LAW_TYPE = {
  GACHA: 'gacha',
  MOVIE: 'movie',
  STORY: 'story'
};

export const REWARD_TYPE = {
  REWARD_TYPE_LIMIT: 'REWARD_TYPE_LIMIT',
  REWARD_TYPE_OTHERS: 'REWARD_TYPE_OTHERS',
  REWARD_TYPE_COMMON: 'REWARD_TYPE_COMMON'
};

export const ITEM_TYPE = {
  ITEM_TYPE_TICKET: 'ITEM_TYPE_TICKET',
  ITEM_TYPE_PHOTO: 'ITEM_TYPE_PHOTO',
};

export const GACHA_TYPE = {
  GACHA_TYPE_LIMIT: 'GACHA_TYPE_LIMIT',
  GACHA_TYPE_COMMON: 'GACHA_TYPE_COMMON'
};
