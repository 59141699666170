import VideoAPI from '@/api/video';
import cloneDeep from 'lodash/cloneDeep';

// initial state
const state = () => ({
  list: [],
  seek: null,
  item: null,
  url: null,
  playList: []
});

// getters
const getters = {
  all: (state) => state.list,
  seek: (state) => state.seek,
  url: (state) => state.url,
  item: (state) => state.item,
  playList: (state) => state.playList
};

// actions
const actions = {
  async list({ state, commit }, payload = null) {
    try {
      const res = await VideoAPI.list(payload);
      if (res.data) {
        commit('setList', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async add({ state, commit }, payload = null) {
    try {
      const res = await VideoAPI.list(payload);
      if (res.data) {
        commit('add', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, id) {
    try {
      const res = await VideoAPI.get(id);
      if (res.data) {
        commit('set', res.data.video);
      }
    } catch (e) {
      throw e;
    }
  },
  async url({ state, commit }, payload) {
    try {
      const res = await VideoAPI.getVideoUrl(payload);
      if (res.data) {
        commit('setUrl', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async playList({ state, commit }, payload) {
    const res = await VideoAPI.playList(payload);
    if (res) {
      commit('setPlayList', res.data.playlist.videos);
    } else {
      try {
        commit('setPlayList', res.data);
      } catch (e) {
        throw e;
      }
    }
  },
  async purchase({ state, commit }, payload) {
    try {
      await VideoAPI.purchase(payload);
    } catch (e) {
      throw e;
    }
  },
  async history({ state, commit }, id) {
    try {
      await VideoAPI.history(id);
      const _item = cloneDeep(state.item);
      _item.isViewed = true;
      commit('set', _item);
    } catch (e) {
      throw e;
    }
  },
  reset({ state }) {
    state.list = [];
    state.seek = null;
    state.item = null;
    state.url = null;
    state.playList = [];
  }
};

const mutations = {
  set(state, data) {
    state.item = data;
  },
  add(state, data) {
    const _all = cloneDeep(state.list);
    _all.push(...data.videos);
    state.list = _all;
    state.seek = data.seek;
  },
  setList(state, data) {
    state.list = data.videos;
    state.seek = data.seek;
  },
  setUrl(state, data) {
    console.log('setUrl data : ', data);
    state.url = data;
  },
  setPlayList(state, payload) {
    state.playList = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
