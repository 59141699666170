import LOTTERY_API from '@/api/lottery';
import cloneDeep from 'lodash/cloneDeep';

// initial state
const state = () => ({
  lottery: null,
  result: null
});

// getters
const getters = {
  lottery: (state) => state.lottery,
  result: (state) => state.result
};

// actions
const actions = {
  async get ({ state, commit, rootGetters }, id) {
    try {
      const res = await LOTTERY_API.get(id);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async reset ({ state, commit }) {
    state.lottery = null;
    state.result = null;
  },
  async purchase ({ state, commit, rootGetters }, data) {
    try {
      const res = await LOTTERY_API.purchase(data);
      if (res.data) {
        commit('result', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async select ({ state, commit }, data) {
    try {
      await LOTTERY_API.select(data);
    } catch (e) {
      throw e;
    }
  },
  async updateResult ({ state, commit, rootGetters }, data) {
    const result = cloneDeep(state.result);
    const rewardId = data[0].rewardId;
    let index = 0;
    // eslint-disable-next-line array-callback-return
    result.rewardResultList.map((rewardResult, _index) => {
      if (rewardResult.accItemList.find((accItem) => accItem.rewardId === rewardId)) {
        index = _index;
      }
    });
    result.rewardResultList[index].accItemList = data;
    state.result = result;
  }
};

const mutations = {
  set (state, data) {
    state.lottery = data;
  },
  result (state, data) {
    state.result = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
