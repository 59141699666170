import axios from 'axios';
import { BASE_URL } from '@/config/const';

class CurrencyAPI {
  async get(payload) {
    return await axios.get(`${BASE_URL}/v2/currency/history`, { params: payload, silent: true });
  }

  async limit() {
    return await axios.get(`${BASE_URL}/v2/currency/limit`);
  }
}

export default new CurrencyAPI();


