import axios from 'axios';
import { BASE_URL } from '@/config/const';

class BackgroundAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/background`, { params: payload });
  }

  async set(payload) {
    return await axios.post(`${BASE_URL}/v2/background`, payload);
  }
}

export default new BackgroundAPI();


