import Vue from 'vue';
import VueRouter from 'vue-router';

import Header from '@/components/header/Header';
import Footer from '@/components/footer/Index';
import { HEADER_TYPE, FOOTER_TYPE, CONTACT_URL, FAQ_URL } from '@/config/const';
import Children from '@/layouts/Children';
import Default from '@/layouts/Default';
import NotFound from '@/layouts/NotFound';
import store from '@/store/index';
import Cookies from 'js-cookie';
import { userInfo } from '@/util';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Default,
    children: [
      {
        path: '/login',
        name: 'login',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: '',
            name: 'login',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "login" */ '@/views/login/Index')
          },
          {
            path: 'mail',
            name: 'login-mail',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "login" */ '@/views/login/Mail')
          },
          {
            path: 'reset',
            name: 'password-reset',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "login" */ '@/views/login/Reset')
          }
        ]
      },
      {
        path: '/signup',
        name: 'signup',
        redirect: '/signup/terms',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: 'top',
            name: 'signup',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "login" */ '@/views/signup/Index')
          },
          {
            path: 'terms',
            name: 'signup-terms',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "signup-create-terms" */ '@/views/signup/Terms')
          },
          {
            path: 'create',
            name: 'signup-create',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "signup-create-mail" */ '@/views/signup/Create')
          },
          {
            path: 'follow',
            name: 'signup-follow',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "signup-create-mail" */ '@/views/signup/Follow')
          }
        ]
      },
      {
        path: '/top',
        name: 'top',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: '',
            name: 'welcome',
            meta: {
              header_type: HEADER_TYPE.FULL,
              footer_type: FOOTER_TYPE.FULL
            },
            component: () => import(/* webpackChunkName: "top" */ '@/views/top/Index')
          }
        ]
      },
      {
        path: '/profile',
        name: 'profile',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'profile',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Index')
          },
          {
            path: 'edit',
            name: 'profile-edit',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.SIMPLE,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile create" */ '@/views/profile/Edit')
          },
          {
            path: 'confirm',
            name: 'profile-confirm',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile confirm" */ '@/views/profile/Confirm')
          },
          {
            path: 'finish',
            name: 'profile-complete',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.FULL,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile finish" */ '@/views/profile/Finish')
          },
          {
            path: 'follow',
            name: 'profile-follow',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile follow" */ '@/views/profile/Follow')
          }
        ]
      },
      {
        path: '/profile/timeline',
        name: 'profile-timeline',
        components: {
          default: Children
        },
        children: [
          {
            path: '',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "profile follow" */ '@/views/profile/timeline/Index')
          }
        ]
      },
      {
        path: '/account',
        name: 'account',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: '',
            name: 'account',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account index" */ '@/views/account/Index')
          },
          {
            path: 'email',
            name: 'account-email',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account email" */ '@/views/account/Email')
          },
          {
            path: 'password',
            name: 'account-password',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account password" */ '@/views/account/Password')
          },
          {
            path: 'support',
            name: 'account-support',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account support" */ '@/views/account/Support')
          },
          {
            path: 'service',
            name: 'account-service',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account service" */ '@/views/account/Service')
          },
          {
            path: 'faq',
            name: 'account-faq'
          },
          {
            path: 'contact',
            name: 'account-contact'
          },
          {
            path: 'oauth',
            name: 'account-oauth',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account oauth" */ '@/views/account/OAuth')
          },
          {
            path: 'logout',
            name: 'account-logout',
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'guide',
            name: 'account-guide',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account guide" */ '@/views/account/Manual')
          },
          {
            path: 'leave',
            name: 'account-leave',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "account leave" */ '@/views/account/Leave')
          },
          {
            path: 'settlement',
            name: 'account-term',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account settlement" */ '@/views/account/Settlement')
          },
          {
            path: 'copyright',
            name: 'account-term',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account copyright" */ '@/views/account/Copyright')
          },
          {
            path: 'block',
            name: 'account-block',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "account block" */ '@/views/account/Block')
          }
        ]
      },
      {
        path: '/mypage',
        name: 'mypage',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'mypage',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "mypage" */ '@/views/mypage/Index')
          },
          {
            path: 'follow',
            name: 'follow-event',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "mypage follow-event" */ '@/views/mypage/FollowEvent')
          }
        ]
      },
      {
        path: '/user/:id',
        name: 'user',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: '',
            name: 'user',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "user" */ '@/views/mypage/Index')
          },
          {
            path: 'follow',
            name: 'user-follow-event',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "user follow" */ '@/views/mypage/FollowEvent')
          }
        ]
      },
      {
        path: '/notification',
        name: 'notification',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: false
        },
        children: [
          {
            path: '',
            name: 'notification',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "notification" */ '@/views/notification/Index')
          },
          {
            path: ':id',
            name: 'notification-detail',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "notification-detail" */ '@/views/notification/Detail')
          }
        ]
      },
      {
        path: '/ticket',
        name: 'ticket',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'ticket',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "notification" */ '@/views/ticket/Index')
          },
          {
            path: ':id',
            name: 'ticket-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "notification-detail" */ '@/views/notification/Detail')
          }
        ]
      },
      {
        path: '/purchase',
        name: 'purchase',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        children: [
          {
            path: 'method',
            name: 'purchase-method',
            meta: {
              header_type: HEADER_TYPE.FULL,
              footer_type: FOOTER_TYPE.FULL,
              hideFooter: true,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-method" */ '@/views/purchase/Method')
          },
          {
            path: 'card',
            name: 'purchase-card',
            meta: {
              header_type: HEADER_TYPE.FULL,
              footer_type: FOOTER_TYPE.FULL,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-card" */ '@/views/purchase/card/Index')
          },
          {
            path: 'card/edit',
            name: 'purchase-card-edit',
            meta: {
              header_type: HEADER_TYPE.FULL,
              footer_type: FOOTER_TYPE.FULL,
              hideFooter: true,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-card-edit" */ '@/views/purchase/card/Edit')
          },
          {
            path: ':id',
            name: 'purchase',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.SIMPLE,
              hideFooter: true,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase" */ '@/views/purchase/Index')
          },
          {
            path: ':id/complete',
            name: 'purchase-complete',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.SIMPLE,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-complete" */ '@/views/purchase/Complete')
          },
          {
            path: ':id/cancel',
            name: 'purchase-cancel',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.SIMPLE,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-cancel" */ '@/views/purchase/Cancel.vue')
          },
          {
            path: ':id/error',
            name: 'purchase-error',
            meta: {
              header_type: HEADER_TYPE.SIMPLE,
              footer_type: FOOTER_TYPE.SIMPLE,
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "purchase-error" */ '@/views/purchase/Error.vue')
          }
        ]
      },
      {
        path: '/order-history',
        name: 'order-history',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'order-history',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "order-history" */ '@/views/purchase/History')
          }
        ]
      },
      {
        path: '/post',
        name: 'post',
        components: {
          default: Children,
          header: Header
        },
        meta: {
          requiresAuth: false
        },
        children: [
          {
            path: ':type/:typeId/:postId',
            name: 'post-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "post-detail" */ '@/views/timeline/detail/Index')
          }
        ]
      },
      {
        path: '/channel',
        name: 'channel',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'channel-list',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "channel-list" */ '@/views/ip/Index')
          },
          {
            path: ':id',
            name: 'channel-detail',
            meta: {
              requiresAuth: false
            },
            component: () => import(/* webpackChunkName: "channel-detail" */ '@/views/ip/Detail')
          },
          {
            path: ':id/story',
            name: 'channel-story-list',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "channel-story-list" */ '@/views/story/Channel')
          },
          {
            path: ':id/story/:storyGroupId',
            name: 'story-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "story-list" */ '@/views/story/Detail')
          },
          {
            path: ':ipId/gacha',
            name: 'gacha-top',
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "gacha-top" */ '@/views/gacha/Index')
          },
          {
            path: ':ipId/gacha/result',
            name: 'gacha-result',
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "gacha-top" */ '@/views/gacha/Result')
          },
          {
            path: ':ipId/draw',
            name: 'draw',
            components: {
              default: Children,
              header: Header,
              footer: Footer
            },
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: ':drawId',
                name: 'draw-top',
                meta: {
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "draw-top" */ '@/views/draws/Index.vue')
              },
              {
                path: ':drawId/prize',
                name: 'draw-prize-top',
                meta: {
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "prize-top" */ '@/views/draws/Prize.vue')
              },
              {
                path: ':drawId/entry',
                name: 'draw-entry-top',
                meta: {
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "entry-top" */ '@/views/draws/Entry.vue')
              }
            ]
          },
          {
            path: ':id/movie',
            name: 'movie-top',
            components: {
              default: Children
            },
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: '',
                name: 'movie-top',
                meta: {
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "movie-list" */ '@/views/movie/Index')
              },
              {
                path: ':videoId',
                name: 'movie-detail',
                meta: {
                  hideFooter: true,
                  // hideHeader: true,
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "movie-detail" */ '@/views/movie/Detail/Index')
              },
              {
                path: ':videoId/unity',
                name: 'movie-detail',
                meta: {
                  hideFooter: true,
                  hideHeader: true,
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "movie-detail" */ '@/views/movie/Detail/Unity')
              }
            ]
          },
          {
            path: ':id/special',
            name: 'special-top',
            components: {
              default: Children
            },
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: '',
                name: 'special-top',
                meta: {
                  requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "special-list" */ '@/views/special/Index')
              }
            ]
          }
        ]
      },
      {
        path: '/collection',
        name: 'collection',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: false
        },
        children: [
          {
            path: '',
            name: 'collection-list',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "collection-list" */ '@/views/collection/Index')
          },
          {
            path: 'viewable',
            name: 'collection-viewable-list',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "collection-viewable-list" */ '@/views/collection/Viewable')
          },
          {
            path: ':ipId/story/privilege/:storyPrivilegeGroupId',
            name: 'collection-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "story-list" */ '@/views/collection/Detail')
          }
        ]
      },
      {
        path: '/address',
        name: 'address',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'address',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "address" */ '@/views/address/Index')
          },
          {
            path: 'edit',
            name: 'address-edit',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "address-edit" */ '@/views/address/Edit')
          },
          {
            path: 'confirm',
            name: 'address-confirm',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "address-confirm" */ '@/views/address/Confirm')
          },
          {
            path: 'finish',
            name: 'address-finish',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "address-finish" */ '@/views/address/Finish')
          }
        ]
      },
      {
        path: '/home',
        name: 'myHome',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'home',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "home" */ '@/views/home/Index')
          }
        ]
      },
      {
        path: '/unity',
        name: 'unity',
        components: {
          default: Children
        },
        meta: {
          requiresAuth: false
        },
        children: [
          {
            path: 'post/:type/:typeId',
            name: 'unity-post',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "post" */ '@/views/timeline/Index')
          },
          {
            path: 'post/:type/:typeId/:postId',
            name: 'post-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "post-detail" */ '@/views/timeline/detail/Unity')
          }
        ]
      },
      {
        path: '/offer',
        name: 'offer',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: ':id',
            name: 'offer',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "offer" */ '@/views/offer/Index')
          }
        ]
      },
      {
        path: '/vote',
        name: 'vote',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'ranking/:id',
            name: 'vote-ranking-detail',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "vote-ranking-detail" */ '@/views/vote/ranking/Index.vue')
          },
          {
            path: 'ranking/:id/ranking',
            name: 'vote-ranking-ranking',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "vote-ranking-ranking" */ '@/views/vote/ranking/Ranking.vue')
          },
          {
            path: 'ranking/:id/target',
            name: 'vote-ranking-target',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "vote-ranking-target" */ '@/views/vote/ranking/Target.vue')
          },
          {
            path: 'ranking/:id/reward',
            name: 'vote-ranking-reward',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "vote-ranking-reward" */ '@/views/vote/ranking/reward/Index.vue')
          },
          {
            path: 'coop/:id',
            name: 'vote-coop-detail',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "vote-coop-detail" */ '@/views/vote/coop/Index.vue')
          },
          {
            path: 'coop/:id/reward',
            name: 'vote-coop-reward',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "vote-coop-reward" */ '@/views/vote/coop/Reward.vue')
          }
        ]
      },
      {
        path: '/lottery',
        name: 'lottery',
        components: {
          default: Children,
          header: Header,
          footer: Footer
        },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: ':id',
            name: 'lottery-top',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "lottery-top" */ '@/views/lottery/Index.vue')
          },
          {
            path: ':id/purchase',
            name: 'lottery-purchase',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "lottery-purchase" */ '@/views/lottery/Purchase.vue')
          },
          {
            path: ':id/result',
            name: 'lottery-result',
            meta: {
              requiresAuth: true,
              hideFooter: true
            },
            component: () => import(/* webpackChunkName: "lottery-result" */ '@/views/lottery/Result.vue')
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: Default,
    children: [
      {
        path: '',
        name: 'NotFound',
        meta: {
          header_type: HEADER_TYPE.SIMPLE
        },
        components: {
          default: NotFound,
          header: Header
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

// router.scrollBehavior = function(to, from , savedPosition) { return { x: 0, y: 0 }; };

router.beforeEach(async(to, from, next) => {
  // console.log('to: ', to);
  if (to.name === 'account-contact') {
    const user = userInfo();
    const userId = user.accountId;
    const userName = user.uniqueName;
    const device = user.device;
    const os = user.os;
    const appVersion = 'null';
    const netType = 'null';
    const path = `${CONTACT_URL}&userAppId=${userId}&userName=${userName}&device=${device}&os=${os}&appVersion=${appVersion}&netType=${netType}`;
    window.open(path, '_blank');
    return;
  }
  if (to.name === 'account-faq') {
    const user = userInfo();
    const userId = user.accountId;
    const userName = user.uniqueName;
    const device = user.device;
    const os = user.os;
    const appVersion = 'null';
    const netType = 'null';
    const type = to.query.type;
    const title = to.query.title;
    const path = `${FAQ_URL}&userAppId=${userId}&userName=${userName}&device=${device}&os=${os}&appVersion=${appVersion}&netType=${netType}&type=${type}&title=${title}`;
    window.open(path, '_blank');
    return;
  }
  store.dispatch('theme/setBackground', '');
  // URLからtokenを設定
  if (to.query.accessToken) {
    store.commit('auth/setToken', to.query);
  }
  if (to.meta.requiresAuth) {
    await store.dispatch('auth/accountAuth', { silent: true }).catch((e) => e);
  } else {
    await store.dispatch('auth/accountAuth', { silent: true }).catch((e) => e);
    // await store.dispatch('auth/cacheAuth', { silent: true }).catch((e) => e);
  }
  // TODO どこでする？
  // await Firebase.login();
  await store.dispatch('time/get').catch((e) => e);

  const emailCache = Cookies.get('email-cache');
  const snsEmailCache = Cookies.get('sns-email-cache');
  const pinCodeCache = Cookies.get('pin-code-cache');

  // 新規登録、認証コード入力途中
  if ((emailCache || snsEmailCache) && to.name !== 'signup-create') {
    next({ path: '/signup/create', query: { page: 'code' } });
  }

  // パスワードリセット、認証コード入力後
  if (pinCodeCache && to.name !== 'password-reset') {
    next({ path: '/login/reset', query: { page: 'input' } });
  }

  const isLogin = store.getters['auth/isLogin'];
  const hasAccount = store.getters['auth/hasAccount'];
  const hasProfile = store.getters['auth/hasProfile'];

  // ログアウトアクション
  if (to.matched.some((record) => record.name === 'account-logout')) {
    const uniqueName = store.getters['profile/uniqueName'];
    Vue.swal({
      title: `「${uniqueName}」`,
      text: '本当にログアウトしてもよろしいですか？',
      reverseButtons: true,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'キャンセル',
      confirmButtonText: 'ログアウトする',
      customClass: {
        container: 'withee-alert',
        confirmButton: 'green',
        cancelButton: ''
      }
    })
      .then(async(result) => {
        if (result.isConfirmed) {
          store.dispatch('profile/reset');
          store.dispatch('address/reset');
          store.dispatch('auth/logout');
          next({ path: '/top' });
        }
      });
    return;
  }

  if (isLogin) { // ログイン状態
    if (hasAccount) { // アカウント作成済み
      if (hasProfile) { // プロフィール登録済み
        await store.dispatch('profile/fetch', { silent: true });

        // ログイン状態でログイン、会員登録ページはホームへリダイレクト
        if (to.matched.some((record) => record.name === 'login' || record.name === 'signup')) {
          if (to.matched.some((record) => record.name === 'signup-follow')) {
            next();
          } else {
            if (to.query.callback && (to.query.callback.includes('https://') || to.query.callback.includes('http://'))) {
              location.href = to.query.callback;
            } else {
              next({ path: '/home' });
            }
          }
        } else {
          if (to.name === 'index') {
            next({ path: '/home' });
          } else {
            next();
          }
        }
      } else {
        if (to.matched.some((record) => record.name === 'profile')) {
          next();
        } else {
          if (from.matched.some((record) => record.name === 'profile')) {
            next();
          } else {
            Vue.swal({
              title: `登録途中の内容があります`,
              text: '新規登録を再開しますか？\n「キャンセル」すると登録内容は失われます',
              reverseButtons: true,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonText: 'キャンセル',
              confirmButtonText: '再開',
              customClass: {
                container: 'withee-alert',
                confirmButton: 'green',
                cancelButton: ''
              }
            })
              .then(async(result) => {
                if (result.isConfirmed) {
                  next({ path: '/profile/edit', query: from.query });
                } else {
                  store.dispatch('profile/reset');
                  store.dispatch('address/reset');
                  store.dispatch('auth/logout');
                  next({ path: '/top' });
                }
              });
          }
        }
      }
    } else { // SNSログインしているが、アカウントを作成していない
      if (to.matched.some((record) => record.name === 'signup-create')) {
        next({ path: '/signup/create' });
      } else {
        next();
      }
    }
  } else if (to.meta.requiresAuth) { // 未ログイン状態でログイン必須ページへ遷移した場合
    store.dispatch('profile/reset');
    const callback = encodeURIComponent(document.location.href);
    next({ path: '/login', query: { callback: callback } });
  } else {
    if (to.name === 'index') {
      next({ path: '/top' });
    } else {
      next();
    }
  }
});

export default router;
