import LiveTalkMemberCheckAPI from '@/api/livetalk/member/check';
import LiveTalkMemberChannelAPI from '@/api/livetalk/member/channel';
const state = () => ({
  livetalk: null,
  channel: null,
  wait: null
});

// getters
const getters = {
  livetalk: (state) => state.livetalk,
  channel: (state) => state.channel,
  wait: (state) => state.wait
};

// actions
const actions = {
  async livetalk({ state, commit }, id) {
    const res = await LiveTalkMemberCheckAPI.get(id);
    state.livetalk = res.data;
  },
  async channel({ state, commit }, { livetalkId, slotId }) {
    const res = await LiveTalkMemberChannelAPI.get(livetalkId, slotId);
    state.channel = res.data;
  },
  async wait({ state, commit }, { livetalkId, slotId }) {
    const res = await LiveTalkMemberChannelAPI.wait(livetalkId, slotId);
    state.wait = res.data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions
};
