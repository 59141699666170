<template>
  <transition name="transition-modal">
    <div
      :class="['modal-backdrop', full ? 'full' : '', stretch ? 'stretch' : '']"
      @click.prevent="onClickBackgrop"
    >
      <div
        ref="body"
        class="modal-inner"
        :style="[
          { width: modalWidth, height: height },
          bg ? { background: bg } : '',
        ]"
        @click.stop
      >
        <button
          v-if="headerClose"
          class="modal-close-btn"
          @click.prevent="close"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
        <header class="modal-header" v-if="$scopedSlots.header">
          <slot name="header"></slot>
        </header>
        <section class="modal-body" v-if="$scopedSlots.body">
          <slot name="body"> </slot>
        </section>
        <footer
          :class="['modal-footer', footerFixed ? 'footer-fixed' : '']"
          v-if="$scopedSlots.footer"
        >
          <slot name="footer"> </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
// import disableScroll from 'disable-scroll';

// import { defineDocumentVisitor } from 'eslint-plugin-vue/lib/utils';

export default {
  name: 'CustomModal',
  props: {
    width: String,
    bg: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    full: {
      type: Boolean,
      default: false
    },
    footerFixed: {
      type: Boolean,
      default: false
    },
    headerClose: {
      type: Boolean,
      default: true
    },
    closeOnBackdrop: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    isIgnoreSetHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSP () {
      return this.$store.getters['screen/isSP'];
    },
    windowInnerHeight () {
      return window.innerHeight;
    }
  },
  data () {
    return {
      scrollY: 0,
      modalWidth: '',
      modalHeight: ''
    };
  },
  watch: {
    'window.innerHeight'() {
      this.setModalBodyHeight();
    }
  },
  mounted () {
    if (this.width) {
      this.modalWidth = this.width;
    } else {
      this.modalWidth = this.isSP ? '95%' : '800px';
    }
    this.scrollY = window.scrollY;
    if (!this.isIgnoreSetHeight) {
      this.setModalBodyHeight();
    }
    // disableScroll.on();
    document.body.classList.add('swal2-shown', 'swal2-height-auto');
    // document.documentElement.classList.add('is-locked');
    // block pointer events
    this.$refs.body.addEventListener('pointermove', this.preventDefault);
  },
  beforeDestroy () {
    // disableScroll.off();
    document.body.classList.remove('swal2-shown', 'swal2-height-auto');
    // resume pointer events
    this.$refs.body.removeEventListener('pointermove', this.preventDefault);
    window.scrollTo(0, this.scrollY);
  },
  methods: {
    onClickBackgrop () {
      if (this.closeOnBackdrop) this.$emit('close');
    },
    close () {
      this.$emit('close');
    },
    preventDefault (e) {
      e.preventDefault();
    },
    setModalBodyHeight () {
      if (this.isIgnoreSetHeight) return;
      this.$nextTick(() => {
        const modalInnerElem = document.querySelector('.modal-backdrop .modal-inner');
        const modalHeaderElem = document.querySelector('.modal-backdrop .modal-header');
        const modalBodyElem = document.querySelector('.modal-backdrop .modal-body');
        const modalContentsElem = document.querySelector('.modal-backdrop .modal-body .modal-body-contents');
        const modalFooterElem = document.querySelector('.modal-backdrop .modal-footer');
        const modalInnerElemHeight = modalInnerElem?.getClientRects()[0].height || 0;
        const modalHeaderElemHeight = modalHeaderElem?.getClientRects()[0].height || 0;
        const modalFooterElemHeight = modalFooterElem?.getClientRects()[0].height || 0;
        if (window.innerHeight < modalInnerElemHeight) {
          modalInnerElem.style.height = '98vh';
          const _height = ((window.innerHeight / 100) * 98) - modalHeaderElemHeight - modalFooterElemHeight;
          if (modalContentsElem) {
            modalContentsElem.style.height = `${_height}px`;
            modalContentsElem.style.overflow = 'scroll';
          } else {
            modalBodyElem.style.height = `${_height}px`;
            modalBodyElem.style.overflow = 'scroll';
          }
        } else {
          modalBodyElem.style = '';
          modalInnerElem.style.height = `${this.height}`;
          modalInnerElem.style.width = `${this.width}`;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables.scss";
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(18, 19, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .modal-inner {
    position: relative;
    background: $white;
    color: $baseBlack;
    overflow-x: auto;
    overflow-y: auto;
    //display: flex;
    //flex-direction: column;
    border-radius: 8px;
  }
  .modal-header,
  .modal-footer {
    //padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin: auto;
    height: 55px;
    color: $baseBlack;
  }
  .modal-footer {
    height: 92px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-body {
    position: relative;
    //flex: 1;
    padding: 20px;
  }
  .modal-close-btn {
    position: absolute;
    top: 0;
    right: 16px;
    font-size: 3rem;
    color: $baseBlack;
    z-index: 1;
  }
  &.full {
    .modal-header {
      height: 112px;
      background: $white;
    }
    .modal-inner {
      position: relative;
      border-radius: 0;
      width: 100% !important;
      height: 100% !important;
      background: $baseWhite;
    }
    .modal-body {
      //background: $white;
      background: $baseWhite;
    }
  }
  &.stretch {
    .modal-inner {
      display: flex;
      flex-direction: column;
    }
    .modal-body {
      flex: 1;
    }
  }
}

.transition-modal-enter-active {
  transition: all 0.3s ease;
}
.transition-modal-leave-active {
  transition: all 0.3s ease;
}
.transition-modal-enter,
.transition-modal-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  .modal-backdrop {
    .modal-inner {
      width: 95%;
      margin: 0 auto;
    }
    .modal-header {
      line-height: 25px;
      font-size: 1.6rem;
      margin: auto;
      height: 53px;
    }
    .modal-body {
      padding: 16px;
    }
    .modal-footer {
      height: 62px;
    }
    .modal-close-btn {
      font-size: 2rem;
      top: 9px;
    }
    &.full {
      .modal-header {
        height: 53px;
      }
      .modal-inner {
        position: relative;
        border-radius: 0;
        width: 100% !important;
        height: 100% !important;
      }
      .modal-body {
        //background: $white;
        background: $baseWhite;
      }
    }
  }
}

.footer-fixed {
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  background: $white !important;
}
</style>
