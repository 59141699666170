import Vue from 'vue';

Vue.directive('ellipsis', function(el, binding) {
  const value = binding.value;
  let str = value;
  const num = parseInt(binding.arg, 10) || 10;
  if (value.length >= num) {
    str = `${value.substring(0, num - 1)}…`;
  }
  el.innerHTML = str;
});

Vue.directive('break', function(el, binding) {
  const value = binding.value;
  el.innerHTML = value.replace(/\\n/g, '<br>');
});
