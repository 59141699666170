var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        'btn',
        _vm.classes,
        _vm.color,
        _vm.round ? 'round' : '',
        _vm.outline ? 'outline' : '',
        _vm.full ? 'full' : '',
        _vm.xSmall ? 'x-small' : '',
        _vm.small ? 'small' : '',
        _vm.medium ? 'medium' : '',
        _vm.xMedium ? 'x-medium' : '',
        _vm.large ? 'large' : '',
        _vm.flat ? 'flat' : ''
    ],style:([_vm.styles, { width: _vm.width, height: _vm.height }]),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }