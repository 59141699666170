// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkTicketAPI from '@/api/livetalk/ticket';

const state = () => ({
  livetalk: null,
  cancelDeadline: null,
  list: []
});

// getters
const getters = {
  list: (state) => state.list,
  cancelDeadline: (state) => state.cancelDeadline,
  livetalk: (state) => state.livetalk
};

// actions
const actions = {
  async get({ state, commit }, { livetalkId }) {
    try {
      const res = await LiveTalkTicketAPI.get(livetalkId);
      console.log(res.data);
      if (res.data) {
        state.livetalk = res.data.livetalk;
        state.cancelDeadline = res.data.cancelDeadline;
        state.list = res.data.list;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
