// initial state
import LiveTalkMemberAPI from '@/api/livetalk/member/livetalk';

const state = () => ({
  livetalk: null,
  ip: null,
  member: null,
  slotList: []
});

// getters
const getters = {
  livetalk: (state) => state.livetalk,
  ip: (state) => state.ip,
  member: (state) => state.member,
  slotList: (state) => state.slotList
};

// actions
const actions = {
  async get({ state, commit }, { livetalkId }) {
    console.log(arguments);
    try {
      const res = await LiveTalkMemberAPI.get(livetalkId);
      if (res.data) {
        state.livetalk = res.data.livetalk;
        state.ip = res.data.ip;
        state.member = res.data.member;
        state.slotList = res.data.slotList;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
