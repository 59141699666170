import axios from 'axios';
import { BASE_URL } from '@/config/const';

class EventAPI {
  async list() {
    return await axios.get(`${BASE_URL}/v1/event`);
  }

  async get(eventId) {
    return await axios.get(`${BASE_URL}/v1/event/${eventId}`, { params: { episodeStatus: 'NORMAL' } });
  }

  async follow(eventId) {
    return await axios.put(`${BASE_URL}/v1/event/${eventId}/follow`);
  }

  async unFollow(eventId) {
    return await axios.delete(`${BASE_URL}/v1/event/${eventId}/follow`);
  }

  async getFollow(accountId) {
    return await axios.get(`${BASE_URL}/v1/event/follow/${accountId}`);
  }
}

export default new EventAPI();
