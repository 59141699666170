<template>
  <div :class="['history-top', isList ? 'set-height' : '']">
    <CustomModal
      id="historyModal"
      class="history"
      full
      v-if="isHistoryDialog"
      @close="close"
    >
      <template v-slot:header>
        <span class="header-title">購入履歴</span>
      </template>
      <template v-slot:body>
        <div class="my-register">
          <div><span>登録年齢</span>{{ (currency && currency.age) }}</div>
          <div><span>購入上限</span>{{ (currency && currency.maxAmount) }}</div>
        </div>
        <ul class="history-list">
          <li class="history-list-item" v-for="history in list" :key="history.assetHistoryId">
            <div class="item-top">
              <div class="item-top-info">
                <div class="date" v-date.datetime="history.transactionTime"></div>
                <template v-if="history.message === 'コイン購入'">
                  <div v-if="history.platformType !== 'UNSPECIFIED'" :class="['platform-type ' + history.platformType]">
                    <div v-if="history.platformType === 'WEB'">
                      <span>{{ history.platformType }}</span>
                    </div>
                    <div v-else>
                      <span>{{ history.platformType === 'IOS' ? 'iOS': '' || history.platformType === 'ANDROID' ? 'Android': '' }}</span>
                    </div>
                  </div>
                </template>
              </div>
              <span class="title">{{ history.message }}</span>
              <span class="sub-title">{{ history.ipName }}</span>
            </div>
            <div class="item-bottom">
              有償：<span :class="[history.optionCoin.paid < 0 ? 'reduce' : '']">{{ checkCoinLimit(history.optionCoin.paid) }}</span>
              無償：<span :class="[history.optionCoin.free < 0 ? 'reduce' : '']">{{ checkCoinLimit(history.optionCoin.free) }}</span>
            </div>
          </li>
        </ul>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
          <div slot="spinner"></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import CustomModal from '@/components/CustomModal';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';
export default {
  layout: 'history',
  name: 'CoinHistoryModal',
  components: {
    CustomModal,
    InfiniteLoading
  },
  props: {
    isHistoryDialog: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      title: 'コイン履歴',
      order: 'DESC',
      limit: 15,
      isList: false,
      infiniteId: 0
    };
  },
  computed: {
    auth() {
      return this.$store.getters['auth/getAuth'];
    },
    currency() {
      return this.$store.getters['currency/currency'];
    },
    list() {
      return this.$store.getters['currency/list'];
    },
    seek() {
      return this.$store.getters['currency/seek'];
    },
    hasNext() {
      return this.seek.hasNext;
    }
  },
  async mounted() {
    if (this.list.length === 0) {
      this.isList = true;
    }
    const params = {
      seek: {
        limit: this.limit,
        order: this.order
      }
    };
    await this.$store.dispatch('currency/get', params);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    moment(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
    checkCoinLimit(coin) {
      if (parseInt(coin, 10) > 9999999) {
        return '9,999,999+';
      }
      return Number(coin).toLocaleString();
    },
    async infiniteHandler($state) {
      const params = {
        seek: {
          limit: this.limit,
          order: this.order,
          lastTransactionTime: this.seek.lastTransactionTime
        }
      };
      if (this.hasNext) {
        await this.$store.dispatch('currency/next', params);
      }
      if (this.hasNext) {
        $state.loaded();
      } else {
        $state.complete();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
::v-deep.history {
  &.modal-backdrop {
    &.full {
    .modal-body {
      .not-stock-text {
        padding: 20rem 0;
        text-align: center;
        font-size: 2rem;
      }
    }
    .modal-header {
      .header-title {
        display: block;
        font-weight: normal;
        color: $baseBlack;
        text-align: center;
      }
    }
    }
  }
}
.history-top {
  .my-register {
    width: 900px;
    margin: 0 auto;
    div {
      font-size: 2rem;
      line-height: 2.1;
      letter-spacing: 1.6px;
      color: $baseDark;
      span {
        padding-right: 20px;
        color: $baseBlack;
      }
    }
  }
  .history-list {
    .history-list-item {
      width: 900px;
      margin: 32px auto 24px;
      border-radius: 8px;
      box-shadow: 0px 2px 2px 0 rgba(95, 99, 104, 0.1);
      background-color: $white;

      .item-top {
        padding: 24px;
        .item-top-info {
          display: flex;
          justify-content: space-between;
        }
        span {
          display: block;
        }
        .date {
          font-size: 1.6rem;
          letter-spacing: 1.28px;
          color: $baseDark;
        }
        .platform-type {
          height: 22px;
          line-height: 22px;
          font-size: 1.2rem;
          color: $baseDark;
          text-align: center;
          letter-spacing: 0.96px;
          border-radius: 11px;
          border: solid 1px $baseDark;
          &.WEB, &.IOS {
            width: 50px;
          }
          &.ANDROID {
            width: 74px;
          }
        }
        .title {
          font-size: 2.4rem;
          letter-spacing: 0.96px;
          color: $baseBlack;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          overflow-wrap: break-word;
        }
        .sub-title {
          font-size: 1.4rem;
          letter-spacing: 1.12px;
          color: $baseBlack;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .item-bottom {
        padding: 9px 23px;
        text-align: right;
        background-color: #f5f5f5;

        span {
          padding-right: 20px;
          &.reduce {
            color: $red;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media screen and ( max-width:1023px) {
  ::v-deep.history {
    &.modal-backdrop {
      &.full {
        .modal-header {
          .header-title {
            margin: 20px 0;
          }
        }
      }
    }
  }
  .history-top {
    .my-register {
      width: 100%;
      div {
        font-size: 1.2rem;
      }
    }
    .history-list {
      .history-list-item {
        width: 100%;
        margin: 15px auto;
        .item-top {
          padding: 11px;
          .date {
            font-size: 1rem;
          }
          .title {
            font-size: 1.2rem;
          }
          .sub-title {
            font-size: 1rem;
          }
        }
        .item-bottom {
          padding: 5px 10px;
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
