const state = () => ({
  header: 0,
  footer: 0,
  isSP: false,
  isHeaderShow: false
});

const getters = {
  header: (state) => state.header,
  footer: (state) => state.footer,
  isSP: (state) => state.isSP,
  isHeaderShow: (state) => state.isHeaderShow
};

// actions
const actions = {
  async header({ state }, height = 0) {
    state.header = height;
  },
  async footer({ state }, height = 0) {
    state.footer = height;
  },
  async isSP({ state }, value) {
    state.isSP = value;
  },
  isHeaderShow({ state }, value) {
    state.isHeaderShow = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
