import AuthAPI from '@/api/auth';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import TimeAPI from '@/api/time';
// initial state
const state = () => ({
  auth: null
});

// getters
const getters = {
  getAuth: (state) => state.auth,
  isLogin: (state) => !!state.auth?.accountId,
  hasAccount: (state) => state.auth?.certs.some((cert) => cert.provider === 'EMAIL'),
  hasProfile: (state) => state.auth?.hasProfile,
  accountId: (state) => state.auth?.accountId
};

// actions
const actions = {
  async create({ commit }, payload) {
    try {
      await AuthAPI.create(payload);
      // 認証コード入力再開用にメアドをキャッシュする
      if (payload.provider) {
        Cookies.set('sns-email-cache', payload.email);
      } else {
        Cookies.set('email-cache', payload.email);
      }
    } catch (e) {
      throw e;
    }
  },
  async resend({ commit }, payload) {
    try {
      await AuthAPI.resend(payload);
    } catch (e) {
      throw e;
    }
  },
  async signupEmail({ commit }, payload) {
    try {
      const res = await AuthAPI.signupEmail(payload);
      if (res.data) {
        Cookies.remove('email-cache');
        Cookies.remove('sns-email-cache');
        await commit('setToken', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async signupProvider({ commit }, payload) {
    try {
      const res = await AuthAPI.signupProvider(payload);
      if (res.data) {
        Cookies.remove('email-cache');
        Cookies.remove('sns-email-cache');
        await commit('setToken', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async checkAuth({ commit }, payload = { silent: false }) {
    try {
      const res = await AuthAPI.checkAuth(payload);
      if (res) {
        commit('setAuth', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async cacheAuth({ commit, state }, payload = { silent: false }) {
    if (state.auth) {
      return true;
    } else {
      try {
        const res = await AuthAPI.checkAuth(payload);
        if (res) {
          commit('setAuth', res.data);
        }
      } catch (e) {
        throw e;
      }
    }
  },
  async accountAuth({ commit, state }, payload = { silent: false }) {
    if (state.auth) {
      return true;
    } else {
      try {
        const res = await AuthAPI.accountAuth(payload);
        if (res) {
          commit('setAuth', res.data);
        }
      } catch (e) {
        throw e;
      }
    }
  },
  async signIn({ commit }, payload) {
    try {
      const res = await AuthAPI.signIn(payload);
      if (res.data) {
        await commit('setToken', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async disableProvider({ commit, dispatch }, provider) {
    try {
      await AuthAPI.disableProvider(provider);
      await dispatch('checkAuth');
    } catch (e) {
      throw e;
    }
  },
  logout({ state }) {
    try {
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      state.auth = null;
      state.preHasProfile = null;
    } catch (e) {
      throw e;
    }
  },
  async discard({ state }) {
    try {
      await AuthAPI.discard();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      state.auth = null;
    } catch (e) {
      throw e;
    }
  },
  async tokenRefresh({ commit, dispatch }) {
    try {
      const res = await AuthAPI.tokenRefresh();
      if (res.data) {
        await commit('setToken', res.data);
        await dispatch('checkAuth', { silent: true });
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setAuth(state, data) {
    state.auth = data;
  },
  async setToken(state, data) {
    Cookies.set('token', data.accessToken, { expires: 999999 });
    Cookies.set('firebaseToken', data.firebaseToken);
    const accessToken = jwt_decode(data.accessToken);
    if (data.refreshToken) {
      const refreshToken = jwt_decode(data.refreshToken);
      const dateFrom = moment.unix(refreshToken.exp);
      console.log('dateFrom', dateFrom);
      let diff = dateFrom.diff(moment(), 'days', true);
      if (accessToken.cp.AccountID) {
        try {
          const res = await TimeAPI.get({ silent: true });
          console.log('res.data.datetime', res.data.datetime);
          diff = dateFrom.diff(moment(res.data.datetime), 'days', true);
        } catch (e) {
          diff = dateFrom.diff(moment(), 'days', true);
        }
      }
      Cookies.set('refreshToken', data.refreshToken, { expires: Math.ceil(diff) });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
