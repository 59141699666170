const state = () => ({
  cameraId: null,
  microphoneId: null,
  speakerId: null
});

// getters
const getters = {
  cameraId: (state) => state.cameraId,
  microphoneId: (state) => state.microphoneId,
  speakerId: (state) => state.speakerId
};

// actions
const actions = {
  async setCamera({ state, commit }, id) {
    state.cameraId = id;
  },
  async setMicrophone({ state, commit }, id) {
    state.microphoneId = id;
  },
  async setSpeaker({ state, commit }, id) {
    state.speakerId = id;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions
};
