export const MainNavItems = function(isLogin = false) {
  if (isLogin) {
    return [
      {
        link: 'https://10antz.co.jp/',
        name: '運営会社'
      },
      {
        key: 'SPECIFIED_COMMERCIAL_TRANSACTIONS',
        name: '特定商取引法に基づく表記'
      },
      {
        key: 'PAYMENT_SERVICES',
        name: '資金決済法に基づく表示'
      },
      {
        key: 'REGISTER_TERMS',
        name: '利用規約'
      },
      {
        key: 'PRIVACY_POLICY',
        name: 'プライバシーポリシー'
      },
      {
        key: 'COMMUNICATION_GUIDELINE',
        name: 'コミュニティガイドライン'
      },
      {
        to: '/account/faq',
        name: 'よくある質問'
      },
      {
        to: '/account/contact',
        name: 'お問い合わせ'
      },
      {
        to: '/account/logout',
        name: 'ログアウト'
      }
    ];
  } else {
    return [
      {
        to: '/signup',
        name: '会員登録'
      },
      {
        to: '/login',
        name: 'ログイン'
      }
    ];
  }
};

