import axios from 'axios';
import { BASE_URL } from '@/config/const';

class LiveTalkInfoAPI {
  async get(id) {
    return await axios.get(`${BASE_URL}/v2/livetalk/livetalk/${id}/talk_info`);
  }

  async post(id, payload) {
    return await axios.patch(`${BASE_URL}/v2/livetalk/livetalk/${id}/talk_info`, payload);
  }
}

export default new LiveTalkInfoAPI();


