import axios from 'axios';
import { BASE_URL } from '@/config/const';

class FollowAPI {
  async list(payload) {
    return await axios.get(`${BASE_URL}/v2/follow/ip`, { params: payload });
  }

  async follow(id) {
    return await axios.post(`${BASE_URL}/v2/follow/ip/${id}`);
  }

  async unfollow(id) {
    return await axios.delete(`${BASE_URL}/v2/follow/ip/${id}`);
  }
}

export default new FollowAPI();


