const state = () => ({
  device: 'web'
});

const getters = {
  get: (state) => state.device
};

// actions
const actions = {
  async set({ state }, device) {
    state.device = device;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
