import livetalk from '@/store/modules/livetalk/member/livetalk';
import group from '@/store/modules/livetalk/member/group';
import device from '@/store/modules/livetalk/member/device';
import talk from '@/store/modules/livetalk/member/talk';
export default {
  modules: {
    member: {
      namespaced: true,
      modules: {
        livetalk,
        group,
        device,
        talk
      }
    }
  }
};
