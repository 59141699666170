import axios from 'axios';
import { BASE_URL } from '@/config/const';

class EpisodeAPI {
  async get(payload) {
    return await axios.get(`${BASE_URL}/v1/event/episode/${payload.eventEpisodeId}/detail`, { params: { eventId: payload.eventId, bbsFilterQuery: payload.bbsFilterQuery, page: payload.page } });
  }

  async getOndemandVideoUrl(payload) {
    return await axios.get(`${BASE_URL}/v1/event/episode/${payload.eventEpisodeId}/on-demand-video`, { params: { eventId: payload.eventId } });
  }

  async count(payload) {
    let params = {
      'booking.eventEpisodeId': payload.eventEpisodeId
    };
    if (payload.isStreaming) {
      params = {
        'viewing.eventEpisodeId': payload.eventEpisodeId
      };
    }
    return await axios.get(`${BASE_URL}/v1/count`, { params: params });
  }
}

export default new EpisodeAPI();
