import axios from 'axios';
import { BASE_URL } from '@/config/const';
import Cookies from 'js-cookie';

class NotificationAPI {
  // async list() {
  //   return await axios.get(`${BASE_URL}/v1/notification/notices`, { params: { platform: 'WEB' } });
  // }

  // async get(id) {
  //   return await axios.get(`${BASE_URL}/v1/notification/notices/${id}`);
  // }

  async list(payload) {
    const token = Cookies.get('token');
    if (token) {
      return await axios.get(`${BASE_URL}/v2/notice`, { params: payload });
    } else {
      return await axios.get(`${BASE_URL}/v2/notice/nologin`, { params: payload });
    }
  }

  async get(payload) {
    const param = Object.assign({}, payload);
    delete param.noticeId;
    const token = Cookies.get('token');
    if (token) {
      return await axios.get(`${BASE_URL}/v2/notice/${payload.noticeId}`, { params: param });
    } else {
      return await axios.get(`${BASE_URL}/v2/notice/nologin/${payload.noticeId}`, { params: param });
    }
  }

  async read(id) {
    return await axios.post(`${BASE_URL}/v2/notice/${id}/read`);
  }

  async acquire(id) {
    return await axios.post(`${BASE_URL}/v2/notice/${id}/acquire`);
  }

  async unreadCount(params) {
    return await axios.get(`${BASE_URL}/v2/notice/unread/count`, { params: params });
  }
}

export default new NotificationAPI();


