import Vue from 'vue';

Vue.directive('cc-expiration', function(el, binding) {
  const value = binding.value || '';
  console.log('value', value);
  if (value.length === 6) {
    el.innerHTML = `${value.substring(0, 4)}/${value.substring(4, 7)}`;
  } else {
    el.innerHTML = '';
  }
});
