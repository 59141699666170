import GachaAPI from '@/api/gacha';

// initial state
const state = () => ({
  preList: [],
  list: [],
  stockout: [],
  gacha: null,
  gachaInfo: null,
  draw: null,
  ticket: null,
  error: null,
});

// getters
const getters = {
  list: (state) => state.list,
  gacha: (state) => state.gacha,
  stockout: (state) => state.stockout,
  draw: (state) => state.draw,
  ticket: (state) => state.ticket,
  gachaInfo: (state) => state.gachaInfo,
  preList: (state) => state.preList,
  error:(state) => state.error
};

// actions
const actions = {
  async list({ state, commit }, id) {
    try {
      const res = await GachaAPI.list(id);
      if (res.data) {
        commit('setList', res.data.gachaList);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, id) {
    try {
      const res = await GachaAPI.get(id);
      if (res.data) {
        commit('set', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async getGachaInfo({ state, commit }, id) {
    try {
      const res = await GachaAPI.getGachaInfo(id);
      if (res.data) {
        commit('setGachaInfo', res.data);
      }
    } catch (e) {
      throw e;
    }
  },
  async stockout({ state, commit }, id) {
    try {
     const res = await GachaAPI.getStockoutList(id);
     if (res.data) {
       commit('setStockout', res.data);
     }
    } catch (e) {
      throw e;
    }
  },
  async draw({ state, commit }, payload) {
    try {
      if (state.error) {
        state.error = null;
      }
      const _payload = {
        ...payload,
        platformType: 'WEB'
      };
      const res = await GachaAPI.draw(_payload);

      if (res && res.data) {
        commit('setDraw', res.data);
      }
    } catch (e) {
      console.error("error!!! : ", e);
      // throw e;
    }
  },
  async agree({ state, commit }, id) {
    try {
      await GachaAPI.agree(id);
    } catch (e) {
      throw e;
    }
  },
  setTicket({ state, commit }, payload) {
    state.ticket = payload;
  },
  setPreList({ state,commit }, payload) {
    state.preList = payload;
  },
  resetDraw({ state,commit }) {
    state.draw = null;
  },
  resetList({ state, commit }) {
    state.list = [];
  },
  setError({ state, commit }, payload) {
    state.draw = null;
    state.error = payload;
  }
};

const mutations = {
  set(state, data) {
    state.gacha = data;
  },
  setList(state, data) {
    state.list = data;
  },
  setStockout(state, data) {
    state.stockout = data.items;
  },
  setDraw(state, data) {
    state.draw = data;
  },
  setGachaInfo(state, data) {
    state.gachaInfo = data.gacha;
  },
  setPreList(state, data) {
    state.preList = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
