import axios from 'axios';
import { BASE_URL } from '@/config/const';
// import { convertZuluTime } from '@/util';

class LiveTalkTicketAPI {
  async get(livetalkId) {
    return await axios.get(`${BASE_URL}/v2/livetalk/livetalk/${livetalkId}/ticket`);
  }
}

export default new LiveTalkTicketAPI();


