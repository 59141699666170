// initial state
const state = () => ({
  headerTitle: ''
});

// mutations
const mutations = {
  set(state, [variable, value]) {
    state[variable] = value;
  }
};

export default {
  state,
  mutations
};
