import TicketAPI from '@/api/ticket';

// initial state
const state = () => ({
  tickets: [],
  single: [],
  all: [],
  ticket: null
});

// getters
const getters = {
  getTickets: (state) => state.tickets,
  getSingle: (state) => state.single,
  getAll: (state) => state.all,
  get: (state) => state.ticket
};

// actions
const actions = {
  async search({ state, commit }, payload) {
    try {
      const res = await TicketAPI.search(payload);
      if (res.data) {
        commit('setList', res.data.models);
      }
    } catch (e) {
      throw e;
    }
  },
  async searchSingle({ state, commit }, payload) {
    try {
      const res = await TicketAPI.search(payload);
      if (res.data) {
        // const viewingIds = uniq(res.data.models.map((val) => val.index.viewingId));
        // // console.log('viewingIds', viewingIds);
        // const data = viewingIds.map((viewingId) => {
        //   const ticket = res.data.models.find((val) => val.index.viewingId === viewingId);
        //   return {
        //     id: viewingId,
        //     salesPrice: ticket.salesPrice,
        //     title: ticket.title,
        //     episodes: res.data.models.filter((val) => val.index.viewingId === viewingId)
        //   };
        // });
        // console.log('data', data);
        commit('setSingle', res.data.models);
      }
    } catch (e) {
      throw e;
    }
  },
  async searchAll({ state, commit }, payload) {
    try {
      const res = await TicketAPI.search(payload);
      if (res.data) {
        commit('setAll', res.data.models);
      }
    } catch (e) {
      throw e;
    }
  },
  async get({ state, commit }, payload) {
    state.ticket = null;
    try {
      const res = await TicketAPI.search(payload);
      const data = res.data.models[0];
      const _data = {
        ...data,
        episodes: res.data.models
      };
      if (res.data) {
        commit('set', _data);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setList(state, data) {
    state.tickets = data;
  },
  setSingle(state, data) {
    state.single = data;
  },
  setAll(state, data) {
    state.all = data;
  },
  set(state, data) {
    state.ticket = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
