import axios from 'axios';
import { BASE_URL } from '@/config/const';

class EpisodeBbsAPI {
  async get(payload) {
    return await axios.get(`${BASE_URL}/v1/event/episode/bbs/${payload.eventEpisodeBbsId}/detail`, { params: payload });
  }
}

export default new EpisodeBbsAPI();
