import FollowAPI from '@/api/follow';

// initial state
const state = () => ({
  list: []
});

// getters
const getters = {
  list: (state) => state.list
};

// actions
const actions = {
  async list({ state, commit }, payload = null) {
    try {
      const res = await FollowAPI.list(payload);
      if (res.data) {
        commit('setList', res.data.list);
      }
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  setList(state, data) {
    state.list = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
