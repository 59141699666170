import VoteRankingAPI from '@/api/vote/ranking';

// initial state
const state = () => ({
  totalVotedAmount: 0,
  participateRewardDescription: '',
  participateRewardStartTime: '',
  participateRewardEndTime: '',
  receivablePeriod: false,
  rewardList: [],
  receiveRewardList: []
});

// getters
const getters = {
  totalVotedAmount: (state) => state.totalVotedAmount,
  participateRewardDescription: (state) => state.participateRewardDescription,
  participateRewardStartTime: (state) => state.participateRewardStartTime,
  participateRewardEndTime: (state) => state.participateRewardEndTime,
  receivablePeriod: (state) => state.receivablePeriod,
  rewardList: (state) => state.rewardList,
  receiveRewardList: (state) => state.receiveRewardList
};

// actions
const actions = {

  async reward({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.participateReward(id);
      state.totalVotedAmount = res.data?.totalVotedAmount || 0;
      state.participateRewardDescription = res.data?.participateRewardDescription;
      state.participateRewardStartTime = res.data?.participateRewardStartTime;
      state.participateRewardEndTime = res.data?.participateRewardEndTime;
      state.receivablePeriod = res.data?.receivablePeriod;
      state.rewardList = res.data?.rewardList || [];
    } catch (e) {
      throw e;
    }
  },
  async receive({ state, commit }, { campaignId, id }) {
    try {
      const res = await VoteRankingAPI.participateRewardReceive(campaignId, id);
      state.receiveRewardList = res.data?.rewardList || [];
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
