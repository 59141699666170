import axios from 'axios';
import { BASE_URL } from '@/config/const';

class VoteRankingAPI {
  async get(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}`);
  }

  async result(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/result/latest`);
  }

  async bonus(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/bonus/active`);
  }

  async bonusPhoto(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/bonus/active/photo`);
  }

  async userReward(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/user_reward`);
  }

  async participateReward(campaignId) {
    return await axios.get(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/participate_reward`);
  }

  async participateRewardReceive(campaignId, rewardId) {
    return await axios.post(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/participate_reward/${rewardId}/receive`);
  }

  async vote(payload, campaignId, targetId, bonusTermId) {
    console.log(payload, campaignId, targetId, bonusTermId);
    return await axios.post(`${BASE_URL}/v2/vote/ranking/campaign/${campaignId}/target/${targetId}/bonus/${bonusTermId}/vote`, payload);
  }
}

export default new VoteRankingAPI();
