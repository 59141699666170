import VoteRankingAPI from '@/api/vote/ranking';

// initial state
const state = () => ({
  campaign: null,
  targetList: [],
  totalVotedAmount: 0,
  votableAmount: 0,
  result: [],
  VoteRewardList: [],
  lastUpdateTime: null,
  nextUpdateTime: null,
  activeBonusTermExists: false
});

// getters
const getters = {
  campaign: (state) => state.campaign,
  targetList: (state) => state.targetList,
  totalVotedAmount: (state) => state.totalVotedAmount,
  votableAmount: (state) => state.votableAmount,
  result: (state) => state.result,
  VoteRewardList: (state) => state.VoteRewardList,
  lastUpdateTime: (state) => state.lastUpdateTime,
  nextUpdateTime: (state) => state.nextUpdateTime,
  activeBonusTermExists: (state) => state.activeBonusTermExists
};

// actions
const actions = {

  async get({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.get(id);
      if (res.data) {
        state.campaign = res.data.campaign;
        state.targetList = res.data.targetList;
        state.totalVotedAmount = res.data.totalVotedAmount;
        state.votableAmount = res.data.votableAmount;
        state.activeBonusTermExists = res.data.activeBonusTermExists;
      }
    } catch (e) {
      throw e;
    }
  },
  async result({ state, commit }, id) {
    try {
      const res = await VoteRankingAPI.result(id);
      state.result = [];
      if (res.data) {
        state.result = res.data.targetResultList.map((result) => {
          Number(result.rank);
          Number(result.votedAmount);
          Number(result.obtainedAmount);
          return result;
        });
        state.lastUpdateTime = res.data.lastUpdateTime;
        state.nextUpdateTime = res.data.nextUpdateTime;
      }
    } catch (e) {
      throw e;
    }
  },
  async vote({ dispatch, state, commit }, data) {
    try {
      const res = await VoteRankingAPI.vote(data.payload, data.campaignId, data.targetId, data.bonusTermId);
      await dispatch('get', data.campaignId);
      state.VoteRewardList = res.data.rewardList;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
