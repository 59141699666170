// initial state
const state = () => ({
  background: ''
});

// getters
const getters = {
  getBackground: (state) => state.background
};

// actions
const actions = {
  setBackground({ commit }, path) {
    commit('setBackground', path);
  }
};

const mutations = {
  setBackground(state, path) {
    state.background = path;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
