<template>
  <footer :class="['app-footer', footer_type === FOOTER_TYPE.SIMPLE ? 'simple' : '' ]">
    <div class="footer-warp is-login" v-if="isLogin">
      <div class="footer-menu-warp">
        <div class="footer-menu-bg">
          <img src="@/assets/images/footer/common_base_tabnav.png" alt="">
        </div>
        <div class="footer-menu-content">
          <div class="footer-menu-list">
            <router-link to="/home" class="footer-menu-icon" active-class="active">
              <img class="footer-menu-icon-off" src="@/assets/images/footer/common_btn_tabnav01_off.png" alt="">
              <img class="footer-menu-icon-on" src="@/assets/images/footer/common_btn_tabnav01_on.png" alt="">
            </router-link>
            <router-link id="footerChannel" to="/channel" class="footer-menu-icon" active-class="active">
              <img class="footer-menu-icon-off" src="@/assets/images/footer/common_btn_tabnav02_off.png" alt="">
              <img class="footer-menu-icon-on" src="@/assets/images/footer/common_btn_tabnav02_on.png" alt="">
            </router-link>
            <router-link id="footerCollection" to="/collection" class="footer-menu-icon" active-class="active">
              <img class="footer-menu-icon-off" src="@/assets/images/footer/common_btn_tabnav03_off.png" alt="">
              <img class="footer-menu-icon-on" src="@/assets/images/footer/common_btn_tabnav03_on.png" alt="">
            </router-link>
            <router-link to="/profile" class="footer-menu-icon" active-class="active">
              <img class="footer-menu-icon-off" src="@/assets/images/footer/common_btn_tabnav04_off.png" alt="">
              <img class="footer-menu-icon-on" src="@/assets/images/footer/common_btn_tabnav04_on.png" alt="">
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-warp" v-else>
      <div class="footer-navigations">
        <div class="footer-navigation">
          <template v-for="nav in navigations">
            <template v-if="nav.key">
              <a @click="remoteConfig(nav.name, nav.key)" class="navigation-item" :key="nav.key">
                <span>{{ nav.name }}</span>
                <img src="@/assets/images/footer/common_icon_59.png" alt="">
              </a>
            </template>
            <template v-else-if="nav.link">
              <a :href="nav.link" class="navigation-item" :key="nav.link" target="_blank">
                <span>{{ nav.name }}</span>
                <img src="@/assets/images/footer/common_icon_59.png" alt="">
              </a>
            </template>
            <template v-else>
              <a :href="nav.to" :key="nav.to" class="navigation-item" active-class="active" @click.prevent="onClickLink(nav)">
                <span>{{ nav.name }}</span>
                <img src="@/assets/images/footer/common_icon_59.png" alt="">
              </a>
            </template>
          </template>
        </div>
      </div>
      <div class="footer-app">
        <div class="footer-app-banners">
          <a :href="APP_IOS_STORE_LINK" class="footer-app-banner footer-app-banner-apple" target="_blank">
            <img src="@/assets/images/appstore.png" alt="">
          </a>
          <a :href="APP_ANDROID_STORE_LINK" class="footer-app-banner footer-app-banner-google" target="_blank">
            <img src="@/assets/images/googleplay.png" alt="">
          </a>
        </div>
      </div>
      <div class="footer-copyright">{{ COPYRIGHT }}</div>
    </div>
    <RemoteConfigModal ref="RemoteConfigModal" />
    <ContactModal ref="ContactModal" />
    <FaqModal ref="FaqModal" />
  </footer>
</template>

<script>
import { FooterNavItems } from '@/data/footer-nav-items';
import { FOOTER_TYPE, COPYRIGHT, APP_IOS_STORE_LINK, APP_ANDROID_STORE_LINK } from '@/config/const';
import RemoteConfigModal from '@/components/RemoteConfigModal.vue';
import FaqModal from '@/components/modal/FaqModal.vue';
import ContactModal from '@/components/modal/ContactModal.vue';

export default {
  name: 'Footer',
  components: {
    ContactModal,
    FaqModal,
    RemoteConfigModal
  },
  props: {
    footer_type: {
      type: Number,
      default: FOOTER_TYPE.FULL
    }
  },
  data() {
    return {
      navigations: FooterNavItems(false),
      FOOTER_TYPE: FOOTER_TYPE,
      COPYRIGHT: COPYRIGHT,
      isLogin: false,
      APP_IOS_STORE_LINK: APP_IOS_STORE_LINK,
      APP_ANDROID_STORE_LINK: APP_ANDROID_STORE_LINK
    };
  },
  computed: {
    auth() {
      return this.$store.getters['auth/getAuth'];
    },
    hasProfile() {
      return this.$store.getters['auth/hasProfile'];
    }
  },
  watch: {
    auth() {
      this.init();
    },
    $route() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const isLogin = !!this.auth?.accountId;
      this.navigations = FooterNavItems(this.isLogin);
      if (isLogin) {
        if (this.hasProfile) {
          this.isLogin = true;
          if (this.$route.matched.some((record) => record.name === 'signup-follow' || record.name === 'profile-confirm' || record.name === 'profile-complete')) {
            this.isLogin = false;
          }
        } else {
          this.isLogin = false;
        }
      } else {
        this.isLogin = false;
      }
    },
    remoteConfig(title, key) {
      this.$refs.RemoteConfigModal.open(title, key);
    },
    onClickLink(nav) {
      if (nav.to === '/account/faq') {
        this.$refs.FaqModal.open();
      } else if (nav.to === '/account/contact') {
        this.$refs.ContactModal.open();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
.app-footer {
  max-width: 100%;
  width: 100%;
  display: block;
}
.footer-warp {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 30px 17px 20px;
  // background-color: transparent;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  &.is-login {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    position: relative;
  }
  .footer-menu-warp {
    position: relative;
    //padding-top: 4px;
    //padding-bottom: 20px;
    overflow: hidden;
    background-color: transparent;
    .footer-menu-bg {
      img {
        width: 100%;
        height: 82px;
      }
    }
    .footer-menu-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .footer-menu-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        margin-top: 20px;
      }
      .footer-menu-icon {
        font-size: 5rem;
        color: $baseBlack;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 94px;
        .footer-menu-icon-off {
          display: block;
        }
        .footer-menu-icon-on {
          display: none;
        }
        img {
          display: block;
          width: 100%;
        }
        &.active {
          .footer-menu-icon-off {
            display: none;
          }
          .footer-menu-icon-on {
            display: block;
          }
        }
      }
    }
  }
  .footer-menu-grad {
    background: linear-gradient(90deg, rgba(255,153,221,1) 0%, rgba(255,153,221,1) 16%, rgba(170,128,255,1) 16%, rgba(170,128,255,1) 32%, rgba(102,153,255,1) 32%, rgba(74,169,255,1) 48%, rgba(12,192,0,1) 48%, rgba(9,197,66,1) 64%, rgba(255,195,0,1) 64%, rgba(255,195,0,1) 80%, rgba(255,77,106,1) 80%, rgba(255,77,106,1) 100%);
    height: 27.5px;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .footer-menu-bg-top {
    height: 27.5px;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    width: calc(100% + 20px);
    margin-left: -10px;
    background: $white;
    //background: gray;
    position: relative;
    z-index: 2;
  }
  .footer-menu-bg-bottom {
    height: 55px;
    border-bottom-left-radius: 50% 30%;
    border-bottom-right-radius: 50% 30%;
    width: calc(100% + 20px);
    margin-left: -10px;
    background: $white;
  }
}
.footer-app {
  padding-bottom: 20px;
  .footer-app-banners {
    display: flex;
    justify-content: flex-start;
  }
  .footer-app-banner {
    display: block;
    height: 40px;
    img {
      height: 100%;
    }
    &.footer-app-banner-apple {
      margin-right: 20px;
    }
    &.footer-app-banner-google {
    }
  }
  .footer-app-text {
    font-size: 1.1rem;
  }
}
.footer-navigations {
  margin-bottom: 20px;
  .footer-navigation {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .navigation-item {
    display: flex;
    //height: 12px;
    line-height: 1;
    padding: 6px;
    font-size: 1rem;
    color: $baseBlack;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 28px;
    cursor: pointer;
    img {
      height: 12px;
    }
  }
}
.footer-copyright {
  font-size: 0.6rem;
  //text-align: center;
  //max-width: 100%;
  color: $baseDark;
}
</style>
