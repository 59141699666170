// import CoinAPI from '@/api/coin';

// initial state
import LiveTalkPersonalAuthAPI from '@/api/livetalk/auth';

const state = () => ({
  personal: {}
});

// getters
const getters = {
  personal: (state) => state.personal
};

// actions
const actions = {
  async get({ state, commit }, { accountId }) {
    console.log(accountId);
    try {
      const res = await LiveTalkPersonalAuthAPI.get(accountId);
      if (res.data) {
        state.personal = res.data;
      }
    } catch (e) {
      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
