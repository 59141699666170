<template>
  <footer :class="['app-footer', footer_type === FOOTER_TYPE.SIMPLE ? 'simple' : '' ]">
    <div class="footer-warp" v-if="footer_type === FOOTER_TYPE.FULL">
      <div class="footer-app">
        <p class="footer-app-title">アプリでもっと楽しむ</p>
        <div class="footer-app-banners">
          <a :href="APP_ANDROID_STORE_LINK" class="footer-app-banner" target="_blank">
            <img src="@/assets/images/googleplay.png" alt="">
          </a>
          <a :href="APP_IOS_STORE_LINK" class="footer-app-banner" target="_blank">
            <img src="@/assets/images/appstore.png" alt="">
          </a>
        </div>
      </div>
      <div class="footer-navigation">
        <template v-for="nav in navigations">
          <template v-if="nav.key">
            <p @click="remoteConfig(nav.name, nav.key)" class="footer-navigation-item" :key="nav.key">{{ nav.name }}</p>
          </template>
          <template v-else-if="nav.link">
            <a :href="nav.link" :key="nav.link" target="_blank" class="footer-navigation-item">
              <span>{{ nav.name }}</span>
            </a>
          </template>
          <template v-else>
            <router-link :to="nav.to" :key="nav.to" class="footer-navigation-item">
              <span>{{ nav.name }}</span>
            </router-link>
          </template>
        </template>
      </div>
      <div class="footer-copyright">{{ COPYRIGHT }}</div>
    </div>
    <RemoteConfigModal ref="RemoteConfigModal" />
  </footer>
</template>

<script>
import { FooterNavItems } from '@/data/footer-nav-items';
import { FOOTER_TYPE, COPYRIGHT, APP_IOS_STORE_LINK, APP_ANDROID_STORE_LINK } from '@/config/const';
import RemoteConfigModal from '@/components/RemoteConfigModal.vue';

export default {
  name: 'Footer',
  components: {
    RemoteConfigModal
  },
  props: {
    footer_type: {
      type: Number,
      default: FOOTER_TYPE.FULL
    }
  },
  data() {
    return {
      navigations: FooterNavItems(false),
      FOOTER_TYPE: FOOTER_TYPE,
      COPYRIGHT: COPYRIGHT,
      APP_IOS_STORE_LINK: APP_IOS_STORE_LINK,
      APP_ANDROID_STORE_LINK: APP_ANDROID_STORE_LINK
    };
  },
  computed: {
    auth() {
      return this.$store.getters['auth/getAuth'];
    }
  },
  watch: {
    auth() {
      this.init();
    },
    $route() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const isLogin = !!this.auth?.accountId;
      this.navigations = FooterNavItems(isLogin);
    },
    remoteConfig(title, key) {
      this.$refs.RemoteConfigModal.open(title, key);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
.app-footer {
  height: $footer-pc;
  background: $white;
  position: relative;
  padding-bottom: 2px;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0px;
    background: linear-gradient(90deg, rgba(255,153,221,1) 0%, rgba(255,153,221,1) 16%, rgba(170,128,255,1) 16%, rgba(170,128,255,1) 32%, rgba(102,153,255,1) 32%, rgba(74,169,255,1) 48%, rgba(12,192,0,1) 48%, rgba(9,197,66,1) 64%, rgba(255,195,0,1) 64%, rgba(255,195,0,1) 80%, rgba(255,77,106,1) 80%, rgba(255,77,106,1) 100%);
  }
  // &.simple {
  //   height: 44px;
  // }
}
.footer-warp {
  max-width: 1020px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0 0;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}
.footer-app {
  text-align: center;
  //margin-top: -55px;
  .footer-app-title {
    font-size: 2.4rem;
    font-weight: 500;
    text-align: left;
    line-break: normal;
    padding-left: 5px;
  }
  .footer-app-banners {
    display: flex;
    justify-content: center;
    padding: 8px 0 16px;
  }
  .footer-app-banner {
    padding: 0 4px;
    height: 40px;
    img {
      height: 100%;
    }
  }
  .footer-app-text {
    font-size: 1.1rem;
    margin: 0;
  }
}
.footer-navigation {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, auto);
  column-gap: 30px;
  row-gap: 15px;
  .footer-navigation-item {
    cursor: pointer;
    font-size: 1.2rem;
    color: $baseBlack;
    //margin-bottom: 15px;
    //padding-right: 30px;
  }
}
.footer-copyright {
  position: absolute;
  bottom: 10px;
  right: 16px;
  font-size: 1.1rem;
  //width: 100%;
  //height: 44px;
  //line-height: 44px;
  text-align: right;
  //max-width: 1020px;
  margin: 0 auto;
  color: #8d8e99;
  letter-spacing: 1.2px;
}
</style>
